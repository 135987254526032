import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-deposit',
  templateUrl: './crypto-credential-account.component.html',
  styleUrls: ['./crypto-credential-account.component.scss', '../common-styles/form-with-table.scss']
})
export class CryptoCredentialAccountComponent implements AfterViewInit, OnInit {
  accountName: string;
  isUserStatusFullVerified = false;
  protected readonly localStorage = localStorage;
  isMasterCardCryptoCredentialRegistered: boolean;
  constructor(public dataService: DataService, private http: HttpClient, private router: Router)  {
  }

  ngOnInit() {
    this.checkMasterCardProfileAndFullVerifiedStatus();
  }
  ngAfterViewInit(): void {

  }

  register() {
    if (this.isUserStatusFullVerified) {
      this.router.navigate(['', { outlets: { popup: ['crypto-credential-sign-up'] } }]);
    } else if (!this.isUserStatusFullVerified) {
      this.router.navigate(['/user-settings/my-profile']);
    }
  }

  checkMasterCardProfileAndFullVerifiedStatus() {
    this.dataService.getUserProfile().toPromise();
    this.dataService.getUserProfile().subscribe((userData) => {
      if (userData && userData.first_name && userData.last_name && userData.iin && userData.country_id && userData.birthday) {
        this.dataService.getUserKycStatus().subscribe(data => {
          console.log('USER STATUS FULL VERIFIED', data);
          this.isUserStatusFullVerified = data.status === 1;
        });
      }
    });
    this.dataService.getMasterCardAccountStatus().subscribe((data) => {
      if (data[0]?.status === 'ACTIVE') {
        this.accountName = data[0]?.accountAlias;
        this.isMasterCardCryptoCredentialRegistered = true;
      } else {
        this.isMasterCardCryptoCredentialRegistered = false;
      }
    });
  }
}
