import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';


@Component({
  selector: 'app-identity-verification',
  templateUrl: './identity-verification.component.html',
  styleUrls: ['./identity-verification.component.scss']
})
export class IdentityVerificationComponent implements OnInit, OnDestroy {
  public statusText = '- coming soon...';
  public isStepGeneralInfo = true;
  public isStepImageLoader = false;
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();
  // public isStepGeneralInfo = false;
  // public isStepImageLoader = true;

  constructor(public dataService: DataService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit(): void {
/*
    setTimeout(() => window.document.title = 'Biteeu', 500);
*/
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public startClickedFunction(data: boolean) {
    this.clearAllSteps();
    this.isStepImageLoader = data;
    // if (this.isStepImageLoader) {
    //   this.statusText = '- starting image uploading';
    // }
  }

  public clearAllSteps() {
    this.isStepGeneralInfo = false;
    this.isStepImageLoader = false;
  }
}
