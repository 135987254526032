export class OrderStopLimitClass {
  direction: string;
  pair: string;
  price: number;
  amount: number;
  total: number;
  triggerPrice: number;
  operateWhen: string;
  validTill: string;
  type: string;

  executed: number;
  reason: string;
  isCancelled: number;

  id?: number;
  userId?: number;
  created_at?: string;
  updated_at?: string;
  bittId?: string;
  // isCancelling?: boolean;

  // executed: 0
  // reason: ""
  // cancelled: 0

  constructor(direction: string,
              pair: string,
              price: number,
              amount: number,
              total: number,
              triggerPrice: number,
              operateWhen: string,
              validTill: string,
              type: string,
              executed: number,
              reason: string,
              isCancelled: number,
              id?: number,
              userId?: number,
              created_at?: string,
              updated_at?: string,
              bittId?: string,
              // isCancelling?: boolean
  ) {
    this.direction = direction;
    this.pair = pair;
    this.price = price;
    this.amount = amount;
    this.total = total;
    this.triggerPrice = triggerPrice;
    this.operateWhen = operateWhen;
    this.validTill = validTill;
    this.type = type;
    this.executed = executed;
    this.reason = reason;
    this.isCancelled = isCancelled;
    this.id = id;
    this.userId = userId;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.bittId = bittId;
    // this.isCancelling = isCancelling;
  }
}
