import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {EurasiaBankPayload} from '../models/eurasia-bank-payload';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BuyCryptoState} from '../models/buy-crypto-state';
import {map} from 'rxjs/operators';
import {TemplateResponse} from '../models/template-response';
import {EukzSpendsDto} from './eukz-spends';

@Injectable({
  providedIn: 'root'
})
export class BuyCryptoService {

  private _buyCryptoApi = `${environment.host}api/v1/acquiring/eukz`;

  constructor(private _httpClient: HttpClient) { }

  getBuyCryptoTemplate(payload: EurasiaBankPayload): Observable<TemplateResponse> {
    return this._httpClient.post<TemplateResponse>(`${this._buyCryptoApi}/buy`, payload, { responseType: 'json' });
  }

  getSellCryptoTemplate(payload: EurasiaBankPayload): Observable<TemplateResponse> {
    return this._httpClient.post<TemplateResponse>(`${this._buyCryptoApi}/sell`, payload, { responseType: 'json' });
  }

  getSpends(): Observable<EukzSpendsDto> {
    return this._httpClient.get<EukzSpendsDto>(`${this._buyCryptoApi}/spends`);
  }

  check(): Observable<BuyCryptoState | undefined> {
    return this._httpClient.get<BuyCryptoState | undefined>(`${this._buyCryptoApi}/check`);
  }

  cancel(id: number, description: string): Observable<void> {
    return this._httpClient.post<void>(`${this._buyCryptoApi}/cancel`, { id, description });
  }
}
