import {Component, OnDestroy} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-enable-account',
  templateUrl: './enable-account.component.html',
  styleUrls: ['./enable-account.component.scss']
})
export class EnableAccountComponent implements OnDestroy {
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(public dataService: DataService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

}
