import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { BCXGRedemption, BCXGRedemptionStatus } from 'src/app/models/bcxg-redemption.model';
import { ShippingDetails } from 'src/app/models/shipping-details.model';
import { balance, goldBars, paymentOptions } from './mock';

@Component({
  selector: 'app-bcxg',
  templateUrl: './bcxg.component.html',
  styleUrls: ['./bcxg.component.scss']
})

export class BcxgComponent implements OnInit, OnDestroy {
  public bcxgPricePerOunceUSD = 0;
  public isLightTheme = false;
  public isPending = true;
  public usdValue = 0;
  public bcxgValue = 0;
  public gramValue = 0;
  public shippingDetails: any;
  public paymentDetais: any;
  public toggle = false;
  public quantity = 0;
  public sortParams = {
    sortByDate: ''
  };

  public toggleSort(param) {
    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = '';
    } else {
      this.sortParams[param] = 'up';
    }
  }

  public redemptionHistory: BCXGRedemption[] = [];

  public balance = balance;
  public paymentOptions = paymentOptions;
  public goldBars = goldBars;

  destroySubject$: Subject<void> = new Subject();

  public form = new FormGroup({
    grams: new FormControl('1'),
    bcxg: new FormControl('0.03215'),
    usd: new FormControl('66.07')
  }, {
    updateOn: 'change'
  });

  public shippingForm = new FormGroup({
    fullName: new FormControl('', [Validators.required, Validators.minLength(2),
      Validators.pattern(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/)]),
    country: new FormControl('', [Validators.required, Validators.pattern(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/)]),
    city: new FormControl('', [Validators.required, Validators.pattern(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/)]),
    state: new FormControl('', [Validators.required, Validators.pattern(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/)]),
    addressLine1: new FormControl('', [Validators.required, Validators.pattern(/^(\w[0-9A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/)]),
    addressLine2: new FormControl('', [Validators.pattern(/^(\w[0-9A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/)]),
    postcode: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^((\+7|7|8)+([0-9]){10})$/)])
  });

  public paymentForm = new FormGroup({
    radio: new FormControl('')
  });

  public steps = 1;

  constructor(
    public dataService: DataService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnDestroy(): void {
    this.steps = 1;
  }

  ngOnInit(): void {
    this.dataService.getMyRedemptions()
      .subscribe((data: BCXGRedemption[]) => {
          // ToDo: Remove 'data.length > 0'.
          if (data && data.length) {
            this.redemptionHistory = data.sort((a, b) => Date.parse(b.date.toString()) - Date.parse(a.date.toString()));
          }
        },
        (e) => console.error(e));

    this.form.get('grams').valueChanges
      .subscribe(value => {
        const roundedGrams = Math.floor(value / 10) * 10;

        this.bcxgValue = roundedGrams * this.form.value.bcxg;
        this.usdValue = roundedGrams * this.form.value.usd;

        this.goldBars = this.goldBars.map(x => ({...x, quantity: 0}));

        const nominals = [
          {
            nominal: 1000,
            count: 0,
            checked: false
          },
          {
            nominal: 100,
            count: 0,
            checked: false
          },
          {
            nominal: 50,
            count: 0,
            checked: false
          },
          {
            nominal: 10,
            count: 0,
            checked: false
          },
        ];

        this.calculateNominals(roundedGrams, nominals);

        this.gramValue = value;
      });
  }

  private calculateNominals(value: number, nominals) {
    if (value < 10) {
      return;
    }

    const notCheckedNominal = nominals.sort((a, b) => b.nominal - a.nominal).filter(x => !x.checked)[0];
    notCheckedNominal.checked = true;

    const {nominal} = notCheckedNominal;
    notCheckedNominal.count = Math.floor(value / nominal);

    this.goldBars.find(x => x.goldGram == nominal).quantity = notCheckedNominal.count;

    this.calculateNominals(value % nominal, nominals);
  }

  showHide(item) {
    item.hidden = !item.hidden;
  }

  cancelRedemptionHistory(id) {
    // ToDo: Hide 'cancel' control if item has been canceled already.
    this.dataService.cancelRedemptionRequestByUser(id)
      .subscribe(success => {
        this.redemptionHistory = this.redemptionHistory.map(x => x.id === id && ({
          ...x,
          status: BCXGRedemptionStatus.CANCELED_BY_USER
        }) || x);
      });
  }

  paymentFormChange() {
    this.paymentDetais = this.paymentForm.value.radio;
  }

  redeemInputChange() {
    if (this.form.value.grams !== this.gramValue) {
      this.bcxgValue = this.gramValue * this.form.value.bcxg;
      this.usdValue = this.gramValue * this.form.value.usd;
    } else if (this.form.value.bcxg !== this.bcxgValue) {
      this.gramValue = this.bcxgValue * this.form.value.grams;
      this.usdValue = this.bcxgValue * this.form.value.usd;
    } else if (this.form.value.usd !== this.usdValue) {
      this.bcxgValue = this.usdValue * this.form.value.bcxg;
      this.gramValue = this.usdValue * this.form.value.grams;
    }
  }

  shippingInputChange() {
    this.shippingDetails = Object.assign(this.shippingForm.value);
  }

  get _fullName() {
    return this.shippingForm.get('fullName');
  }

  get _country() {
    return this.shippingForm.get('country');
  }

  get _city() {
    return this.shippingForm.get('city');
  }

  get _state() {
    return this.shippingForm.get('state');
  }

  get _addressLine1() {
    return this.shippingForm.get('addressLine1');
  }

  get _addressLine2() {
    return this.shippingForm.get('addressLine2');
  }

  get _postcode() {
    return this.shippingForm.get('postcode');
  }

  get _email() {
    return this.shippingForm.get('email');
  }

  get _phoneNumber() {
    return this.shippingForm.get('phoneNumber');
  }

  nextStep() {
    if (this.steps === 1) {
      this.dataService.getAccountBCXGValue()
        .subscribe((data: number) => {
          this.balance.availableBCXG = data;
          this.balance.availableOZ = data;
        }, e => console.error(e));
      this.dataService.getGoldPricePerOunceUSD()
        .subscribe((data: number) => this.balance.goldValue = data, e => console.error(e));
    }

    this.steps++;
    if (this.steps > 5) {
      this.steps = 5;
    }
  }

  backStep() {
    this.steps--;
    if (this.steps < 1) {
      this.steps = 1;
    }
  }

  getGoldPriceForOZ(oz) {
    return oz * this.balance.goldValue;
  }

  gramToOZ(gram) {
    // Formula from Google's weight calculator.
    return +(gram / 28.35).toFixed(6);
  }

  public increaseQuantity(itemName) {
    const thing = this.goldBars.find(obj => obj.goldSize === itemName);

    if (this.gramToOZ(this.gramValue + thing.goldGram) > this.balance.availableBCXG) {
      return;
    }

    thing.quantity++;
    this.gramValue += thing.goldGram;

    this.redeemInputChange();
  }

  public decreaseQuantity(itemName) {
    const thing = this.goldBars.find(obj => obj.goldSize === itemName);

    thing.quantity--;
    this.gramValue -= thing.goldGram;

    this.redeemInputChange();

    if (thing.quantity <= 0) {
      for (let i = 0; this.goldBars.length > i; i++) {
        this.goldBars[i].quantity = 0;
      }
      this.gramValue = this.bcxgValue = this.usdValue = 0;
      this.steps = 2;
    }
  }

  get invalidBalance(): boolean {
    return this.balance.availableBCXG < this.bcxgValue;
  }

  public confirmRedemption() {
    if (this.invalidBalance) {
      console.error('invalid balance');
      return;
    }

    this.dataService.createRedemption(new BCXGRedemption(
      null,
      'Redemption',
      moment().toDate(),
      this.bcxgValue,
      this.paymentDetais,
      null,
      new ShippingDetails(
        null,
        this._fullName.value,
        this._country.value,
        this._city.value,
        this._state.value,
        this._addressLine1.value,
        this._addressLine2.value,
        this._postcode.value,
        this._email.value,
        this._phoneNumber.value
      )
    )).subscribe(() => {
      this.steps = 1;
      this.dataService.getMyRedemptions()
        .subscribe((data: BCXGRedemption[]) => {
            if (data && data.length) {
              this.redemptionHistory = data;
            }
          },
          (e) => console.error(e));
    }, (e) => console.error(e));
  }

  public isBcxgNotAdded() {
    return this.gramValue == 0;
  }
}
