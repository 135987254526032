import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {ORDER_STOP_LIMIT} from '../../../app.constants';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss', '../common-styles/form-with-table.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {

  tabs = ['DEPOSITS', 'WITHDRAWALS', 'OPEN_ORDERS', 'CLOSED_ORDERS'];
  mobileTabs = ['DEPOSITS', 'WITHDRAWALS'];
  selectedTab = 'DEPOSITS';
  isMobile = window.innerWidth < 860;

  public orderStopLimit = ORDER_STOP_LIMIT;

  openOrdersDirtySubject$: Subject<void> = new BehaviorSubject(null);

  deposits$ = this.dataService.getDeposits().pipe(tap((data) => data.length ? this.hasData.add('DEPOSITS') : null));
  withdrawals$ = this.dataService.getWithdrawals().pipe(tap((data) => data.length ? this.hasData.add('WITHDRAWALS') : null));
  openOrders$ = this.openOrdersDirtySubject$.pipe(
    switchMap(
      () => this.dataService.getOpenOrders('yes').pipe(tap((data) => data.length ? this.hasData.add('OPEN_ORDERS') : null))
    )
  );
  closedOrders$ = this.dataService.getOrderHistory('yes').pipe(tap((data) => data.length ? this.hasData.add('CLOSED_ORDERS') : null));

  hasData = new Set<string>();

  destroySubject$: Subject<void> = new Subject();

  downloadLinks = {
    'DEPOSITS': 'api/v1/user/deposits-to-csv',
    'WITHDRAWALS': 'api/v1/user/withdrawals-to-csv',
    'OPEN_ORDERS': 'api/v1/order/open-to-csv',
    'CLOSED_ORDERS': 'api/v1/order/history-to-csv',
  };

  constructor(public dataService: DataService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event?) {
    this.isMobile = window.innerWidth < 860;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  downloadCsv() {
    this.dataService.downloadFile(this.downloadLinks[this.selectedTab], `${this.selectedTab.toLowerCase()}.csv`);
  }

  public cancelOrder(orderId, type: string, _order?) {
    if (type.toUpperCase().includes(this.orderStopLimit.toUpperCase())) {
      this.cancelStopLimitOrder(orderId);
    } else {
      this.cancelLimitOrder(orderId);
    }
  }

  public cancelLimitOrder(orderId) {
    this.dataService.cancelOpenOrder(orderId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
          this.openOrders$ = this.dataService.getOpenOrders('yes');
        },
        err => {
          console.log('cancelOrder error', err);
        }
      );
  }

  public cancelStopLimitOrder(orderId) {
    this.dataService.cancelOpenStopLimitOrder(orderId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(_res => {
          this.openOrders$ = this.dataService.getOpenOrders('yes');
        },
        err => {
          console.log('cancelOrder error', err);
        }
      );
  }

  public cancelAll(orders: any[]) {
    this.dataService.cancelOpenOrders(orders.map((o) => o.id)).toPromise().then(
      () => this.openOrdersDirtySubject$.next()
    );
  }
}
