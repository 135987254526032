export const en = {
    header: {
        navigation: [
            {
                id: 1,
                url: 'https://iampartof.org/',
                name: 'IAMPARTOF - CHARITY',
            },
            // {
            //     id: 2,
            //     url: 'https://trade.biteeu.com/bcxg',
            //     name: 'BCXG',
            // },
            {
                id: 3,
                url: '/tutorial',
                name: 'TUTORIAL',
            },
            {
                id: 4,
                url: 'http://trade.biteeu.com/login/en',
                name: 'LOGIN',
            },
            {
                id: 5,
                url: 'http://trade.biteeu.com/sign-up/en',
                name: 'SIGNUP',
            },
        ],
    },
    mobileView: 'Stay connected with our mobile app',
    home: {
        promotionSection: {
            banner2: {
                title: 'We are pleased to announce a partnership with the UK regulated charity',
                buttonText: 'READ MORE',
            },
        },
        chartSection: {
            title: 'Biteeu Exchange — when global goes local.',
            description: 'Fully Licensed and Compliant virtual currency exchange from the European Union.',
            register: 'register now',
            explore: 'explore markets',
        },
        partnersSection: 'Partners',
        featuresSection: {
            title: 'Main features',
            features: [
                {
                    id: 1,
                    src: 'images/features/safety.svg',
                    title: 'Safety',
                    subtitle: 'Our main priority is the safety of your funds and data. All information is stored on remote servers and protected 24/7.',
                },
                {
                    id: 2,
                    src: 'images/features/platform.svg',
                    title: 'Multiplatform support',
                    subtitle: 'Web, iOS, Android, Windows, MacOS.',
                },
                {
                    id: 3,
                    src: 'images/features/comission.svg',
                    title: 'Low fees',
                    subtitle: 'The commission structure is completely transparent and there are no hidden fees.',
                },
                {
                    id: 4,
                    src: 'images/features/multilingual.svg',
                    title: '24/7 Multilingual support',
                    subtitle: 'Our support team has legal and technical knowledge and speaks various languages, including English, Russian, Chinese and Turkish.',
                },
                {
                    id: 5,
                    src: 'images/features/chart-f.svg',
                    title: 'Advanced trading technical instruments',
                    subtitle: 'Fully customizable TradingView graph with advanced trading instruments.',
                },
                {
                    id: 6,
                    src: 'images/features/legal.svg',
                    title: 'Legal and Compliance',
                    subtitle: 'Our exchange is fully licensed and compliant with local laws and regulations.',
                },
            ],
        },
        tradingAppSection: {
            title: 'Stay connected with our mobile app',
        },
    },
    tutorial: {
        title: 'BITEEU Tutorial videos:',
    },
    about: {
        title: 'About Us',
        texts: [
            'BITEEU DCX OÜ was founded in 2019 in the Republic of Estonia by professionals with solid experience in banking sector, stock market and international public companies. It is a fully licensed and compliant virtual currency exchange in the European Union. We highly value integrity, safety and security. Therefore, we have developed our anti-money laundering and know your customer policies in accordance with the strict requirements of local laws and International acts.',
            'BITEEU DCX OÜ in collaboration with Bittrex uses its advanced technology to offer our customers safe, reliable and comfortable trading experience. Our platform supports several languages including English, Russian and Chinese. It provides round-the-clock customer service and ensures the safety of your assets and data.',
            'BITEEU DCX OÜ platform will also be available on Android and iOS mobile devices. Currently the BITEEU DCX OÜ wallet system launches with more than 50 virtual currencies, and through a partnership with Bittrex, BITEEU DCX OÜ will continuously be adding more virtual currencies',
            'Our licenses issued by the Police and Border Guard Board in the Republic of Estonia:',
            '– Financial services, Providing a virtual currency service Number FVT000229',
        ],
    },
    footer: {
// {
//   title: true,
//     label: 'Support Center',
//   children: [
//   {
//     id: 24,
//     href: '/faq',
//     label: 'FAQ ',
//     isExternal: true,
//     target: '_self'
//   },
// ]
// },
        support: [
          {
            id: 24,
            title: true,
            label: 'SUPPORT_CENTER',
            children: [
              {
                id: 25,
                href: '/faq',
                label: 'FAQ',
                isExternal: true,
                target: '_self'
              }
            ]
          }
        ],
        socials: [
          {
            id: 1,
            href: 'https://t.me/intebixkz',
            label: 'Follow us on Telegram',
            src: 'assets/img/socials/telegram-sm.svg',
          },
          {
                id: 2,
                href: 'https://twitter.com/Intebix',
                label: 'Follow us on Twitter',
                src: 'assets/img/socials/twitter-sm.svg',
            },
            {
                id: 3,
                href: 'https://instagram.com/intebix?igshid=YmMyMTA2M2Y=',
                label: 'Follow us on Instagram',
                src: 'assets/img/socials/insta-sm.svg',
            },
            {
                id: 4,
                href: 'https://www.tiktok.com/@intebix?_t=8qe3wh34Mvn&_r=1',
                label: 'Follow us on Tik Tok',
                src: 'assets/img/socials/tiktok.svg',
            },
            {
                id: 5,
                href: 'https://www.linkedin.com/company/intebix/posts/?feedView=all',
                label: 'Follow us on Linkedin',
                src: 'assets/img/socials/linkedin.svg',
            },
        ],
        usefulLinks: [
            {
              title: true,
              label: 'About us',
              children: [
                {
                  id: 1,
                  href: '/about-us',
                  label: 'About us',
                  isExternal: true,
                  target: '_self'
                },
                {
                  id: 4,
                  href: '/cookie-policy.pdf',
                  label: 'Cookie policy',
                  isExternal: true,
                  target: '_blank'
                },
                {
                  id: 3,
                  href: '/Terms-of-Use.pdf',
                  label: 'Terms of use',
                  isExternal: true,
                  target: '_blank'
                },
                {
                  id: 101,
                  href: '/privacy-policy.pdf',
                  label: 'Privacy Policy',
                  isExternal: true,
                  target: '_blank'
                },
                {
                  id: 102,
                  href: '/Admission-to-Trading-Rules.pdf',
                  label: 'Admission To Trading Rules',
                  isExternal: true,
                  target: '_blank'
                }
              ]
            },
            {
              title: true,
              label: 'Products',
              children: [
                {
                  id: 11,
                  href: '/wallet-status',
                  label: 'Exchange'
                },
                {
                  id: 12,
                  href: 'https://iampartof.org/',
                  label: 'Charity - Iampartof',
                  isExternal: true,
                  target: '_blank'
                },
              ]
            },
            {
              title: true,
              label: 'Services',
              children: [
                {
                  id: 14,
                  href: '#',
                  label: 'Mobile app'
                },
                {
                  id: 15,
                  href: '/wallet-status',
                  label: 'Wallet status'
                },
                {
                  id: 16,
                  href: '/fees-and-limits',
                  label: 'Fees & Limits'
                },
              ],
            },
            {
              title: true,
              label: 'Learn',
              children: [
                {
                  id: 18,
                  href: '#',
                  label: 'Tutorials'
                },
              ]
            },
        ],
        asSeen: 'As seen in:',
    },
};
