import { Injectable } from '@angular/core';
import {FormControl} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  numberValidator(control: FormControl): {[s: string]: boolean} {
    const data = (control && control.value.length) ? control.value : '';

    const result = data.length ? this.getNumberFromString(data) : 0;
    // console.log(result);

    if (result <= 0) {
      return {'amountCrypto': true};
    }
    return null;
  }

  getNumberFromString(value: string): any {
    const regex = /^[0-9]{0,50}([,.][0-9]{0,50})?$/;
    const anArray = String(value).split('');
    const isDot = false;

    for (let i = 0; i < anArray.length; i++) {
      if (!regex.test(anArray[i])) {
        anArray[i] = '';
      }
    }
    // console.log(anArray);
    return Number(anArray.join(''));
  }

  getDecimalsWithFixedLength(_value: number, toString?: number): number {
    let value = _value + '';
    if (value.length > 2) {
      console.log('--------');
      const decimalPartDot = (value.split('.') && value.split('.')[1]) ? value.split('.')[1] + '' : undefined;
      console.log('decimalPartDot', decimalPartDot);

      value = (Math.floor(_value * 1000000) / 1000000).toFixed(6) + '';
      return +value;
    } else return +value;
    //let dottedString: string;
    //let result = '';
    //
    //if (!isNaN(value)) {
    //  //dottedString = +(String(value).replace( /,/g, '.' )) + '';
    //  dottedString = value + '';
    //  console.log('dottedString', dottedString);
    //  const decimalPart = dottedString.split('.')[1] + '';
    //  console.log('decimalPart', decimalPart);
    //  result = (decimalPart && decimalPart.length > 6)
    //    ? ((Math.floor(+dottedString * 1000000) / 1000000).toFixed(6) + '')
    //    : dottedString;
    //} else {
    //  result = value + '';
    //}
    //
    //console.log(+result);
    //
    //return +result;
  }

  getNumberWithDecimalsFromString(value: string, toString?: number): string {
    const regex1 = /^[0-9.,]$/;
    let regex2;
    if (toString === 2) {
      regex2 = /^[0-9]{1,13}([,.][0-9]{0,2})?$/;
    } else {
      regex2 = /^[0-9]{1,15}([,.][0-9]{0,8})?$/;
    }
    const anArray = String(value).replace( /,/g, '.' ).split('');
    // console.log('--------', anArray);
    for (let i = 0; i < anArray.length; i++) {
       //console.log(regex1.test(anArray[i]));
      if (!regex1.test(anArray[i])) {
        anArray[i] = '';
      }
    }
    anArray.join('').split('');
     //console.log(anArray);

    const arr = [];
    for (let i = 0; i < anArray.length; i++) {
      let temp = '';
      for (let j = 0; j <= i; j++) {
        temp += anArray[j];
      }
      if (regex2.test(temp)) {
        arr.push(anArray[i]);
      }
    }
    // console.log(arr);
    return (arr.join('') || null);
  }

  validateEnglishLettersNumbersSymbols(value: string): string {
    // const regex1 = /^[a-zA-Z0-9,.!? +\s\-_!"#$%&'()*:;<=>@^`{|}~]*$/;
    const regex1 = /^[a-zA-Z0-9,. /+"#&'()*:;<=>@^`\s\-]*$/;

    const anArray = String(value).split('');
    for (let i = 0; i < anArray.length; i++) {
      if (!regex1.test(anArray[i])) {
        anArray[i] = '';
      }
    }
    anArray.join('').split('');
    return (anArray.join('') || null);
  }

  // from KOSTYA
  validateAmount (amount: number) {
    let regex = /[0-9]|\./;
    let anArray = String(amount).split('');
    let hasDot = false;
    for (let i = 0; i < anArray.length; i++) {
      if (!regex.test(anArray[i]) || (hasDot && anArray[i] === '.')) {
        anArray[i] = '';
      }
      if (anArray[i] === '.') {
        hasDot = true;
      }
    }
    return anArray.join('') || null;
  }

  getNumberIntegerFromString(value: string): number {
    const regex1 = /^[0-9]{1,50}$/;
    const anArray = String(value).replace( /,/g, '.' ).split('');
    for (let i = 0; i < anArray.length; i++) {
      if (!regex1.test(anArray[i])) {
        anArray[i] = '';
      }
    }
    return Number(anArray.join(''));
  }

  getNumberWithoutLastCommaOrDotFromString(value: string): string {
    const regex = /^[0-9.,]$/;
    const anArray = String(value).replace( /,/g, '.' ).split('');
    for (let i = 0; i < anArray.length; i++) {
      if (!regex.test(anArray[i])) {
        anArray[i] = '';
      }
    }
    // console.log(anArray.join(''));
    return Number(anArray.join('')) + '';
  }

  getValidationMessageFromInput(value: string): string {
    if (value) {
      const values = String(value).split('');
      const _regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+~\/|"':,;><=^{}().$@$!%*?#_&])[A-Za-z\d-+~\/|"':,;><=^{}(){}().$@$!%*?#_&]{8,30}/;
      const regexSmallCharacter = /[a-z]/;
      const regexBigCharacter = /[A-Z]/;
      const regexNumber = /\d/;
      const regexSymbol = /[-+~\/|"':,;><=^{}().$@$!%*?#_&]/;
      const minLengthPassword = 8;
      const minLengthSmallCharacters = 3;
      const minLengthCapitalLetters = 1;
      const minLengthNumbers = 1;
      const minLengthSymbols = 1;
      let arrSmallCharacters = [];
      let arrCapitalLetters = [];
      let arrNumbers = [];
      let arrSymbols = [];
      let message = '';

      for (let i = 0; i < values.length; i++) {
        if (regexSmallCharacter.test(values[i])) {arrSmallCharacters.push(values[i]);
        } else if (regexBigCharacter.test(values[i])) {arrCapitalLetters.push(values[i]);
        } else if (regexNumber.test(values[i])) {arrNumbers.push(values[i]);
        } else if (regexSymbol.test(values[i])) {arrSymbols.push(values[i]);
        }
      }

      if (arrSmallCharacters.length<minLengthSmallCharacters) {
        const s = arrSmallCharacters.length === 2 ? '' : 's';
        message = `Please add ${minLengthSmallCharacters - arrSmallCharacters.length} more character${s}`;
      }
      if (arrNumbers.length<minLengthNumbers) {
        const newMessage = (arrSmallCharacters.length<minLengthSmallCharacters) ? ', a number' : 'Please add a number';
        message += newMessage;
      }
      if (arrCapitalLetters.length<minLengthCapitalLetters) {
        if (arrSymbols.length<minLengthSymbols) {
          if (arrSmallCharacters.length<minLengthSmallCharacters) {
            const newMessage = (arrNumbers.length<minLengthNumbers) ? ', a capital letter' : ', a capital letter';
            message += newMessage;
          } else {
            const newMessage = (arrNumbers.length<minLengthNumbers) ? ', a capital letter' : 'Please add a capital letter';
            message += newMessage;
          }
        } else {
          if (arrSmallCharacters.length<minLengthSmallCharacters) {
            const newMessage = (arrNumbers.length<minLengthNumbers) ? ' and a capital letter' : ' and a capital letter';
            message += newMessage;
          } else {
            const newMessage = (arrNumbers.length<minLengthNumbers) ? ' and a capital letter' : 'Please add a capital letter';
            message += newMessage;
          }
        }
      }

      if (arrSymbols.length<minLengthSymbols) {
        if (arrSmallCharacters.length<minLengthSmallCharacters) {
          if (arrNumbers.length<minLengthNumbers) {
            const newMessage = (arrCapitalLetters.length<minLengthCapitalLetters) ? ' and a symbol' : ' and a symbol';
            message += newMessage;
          } else {
            const newMessage = (arrCapitalLetters.length<minLengthCapitalLetters) ? ' and a symbol' : ' and a symbol';
            message += newMessage;
          }
        } else {
          if (arrNumbers.length<minLengthNumbers) {
            const newMessage = (arrCapitalLetters.length<minLengthCapitalLetters) ? ' and a symbol' : ' and a symbol';
            message += newMessage;
          } else {
            const newMessage = (arrCapitalLetters.length<minLengthCapitalLetters) ? ' and a symbol' : 'Please add a symbol';
            message += newMessage;
          }
        }
      }

      if (value.length>5 && value.length<minLengthPassword
        && arrSmallCharacters.length>=minLengthSmallCharacters
        && arrCapitalLetters.length>=minLengthCapitalLetters
        && arrNumbers.length>=minLengthNumbers
        && arrSymbols.length>=minLengthSymbols) {
        const miss = minLengthPassword - arrSmallCharacters.length - arrCapitalLetters.length - arrNumbers.length - arrSymbols.length;
        const s = miss>1 ? 's' : '';
        message = `Please input ${miss} sign${s} to complete the minimum password length`;
      }
      return message;
    } else return '';
  }


  removeNullsFromFloatNumber(value: number, toFixed: number): string {
    // console.log(value, 'toFixed',toFixed);
    let result = '';
    const first = ('' + value).split('.')[0].split('');
    let last = ('' + value).split('.')[1];
    if (last) {
      last = <any>('' + +last.split('').reverse().join('')).split('').reverse().join('').split('.');
      result = first.join('') + '.' + last;
      // console.log(typeof result, result);
      // console.log('first', first, ', last ',last);
      if (last[0].length > toFixed) {
        // console.log("'' + (+result).toFixed(toFixed)");
        return '' + (+result).toFixed(toFixed);
      } else {
        return result;
      }
    }
    result = first.join('');
    return result;
  }

}
