export const FOOTER_LINKS = [
  {
    title: true,
    label: 'ABOUT_US',
    children: [
      {
        id: 1,
        href: '/about-us',
        label: 'ABOUT_US',
        isExternal: true,
        target: '_self'
      },
      {
        id: 4,
        href: '/cookie-policy.pdf',
        label: 'COOKIE_POLICY',
        isExternal: true,
        target: '_blank'
      },
      {
        id: 3,
        href: '/Terms-of-Use.pdf',
        label: 'TERMS_OF_USE',
        isExternal: true,
        target: '_blank'
      },
      {
        id: 101,
        href: '/privacy-policy.pdf',
        label: 'PRIVACY_POLICY',
        isExternal: true,
        target: '_blank'
      },
      {
        id: 102,
        href: '/Admission-to-Trading-Rules.pdf',
        label: 'ADMISSION_TO_TRADING_RULES',
        isExternal: true,
        target: '_blank'
      },
      {
        id: 103,
        href: 'compliance_policy.pdf',
        label: 'COMPLIANCE_POLICY',
        isExternal: true,
        target: '_blank'
      }
    ]
  },
  {
    title: true,
    label: 'PRODUCTS',
    children: [
      {
        id: 12,
        href: 'https://iampartof.org/',
        label: 'CHARITY_IAMPARTOF',
        isExternal: true,
        target: '_blank'
      },
    ]
  },
//   {
//     title: true,
//     label: 'SERVICES',
//     children: [
//       {
//         id: 14,
//         href: '#',
//         label: 'MOBILE_APP'
//       },
// /*
//       {
//         id: 15,
//         href: '/wallet-status',
//         label: 'WALLET_STATUS'
//       },
// */
//       {
//         id: 16,
//         href: '/fees-and-limits',
//         label: 'FEES_AND_LIMITS'
//       },
//     ],
//   },
//   {
//     title: true,
//     label: 'LEARN',
//     children: [
//       {
//         id: 18,
//         href: '#',
//         label: 'TUTORIALS'
//       },
//     ]
//   }
];
