import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'homeSearch'
})
export class HomeSearchPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args) args = args.toUpperCase(); else return value;

    return value.filter(item => {
      return item.MarketName.includes(args);
    });
  }

}
