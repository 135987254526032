import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technodom',
  templateUrl: './technodom.component.html',
  styleUrls: ['./technodom.component.scss']
})
export class TechnodomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
