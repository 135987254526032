import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {MyProfileInterface} from '../../../../models/my-profile.model';
import snsWebSdk from '@sumsub/websdk';
import {GeoState} from '../../../../store/geo.state';
import {fromMobx} from '../../../../store/rx-from-mobx';
import {ProfileState} from '../../../../store/profile.state';
import {LanguageState} from '../../../../store/language.state';
import {reaction} from 'mobx';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public isResidenceCountryInBlackList = true;
  public isCitizenshipInBlackList = true;
  public isSpinnedStatus = true;
  public isUserCompletedProfileForm = false;
  public kycStatus: number;
  public isUploadedImages: number;
  public userEmail: string;
  public showLoader = true;
  public preferredLanguage = 'en';
  destroySubject$: Subject<void> = new Subject();
  private autorunDisposer: () => void;

  @Output()
  public onStartClicked = new EventEmitter(false);
  constructor(
    public dataService: DataService,
    private geoState: GeoState,
    private profileState: ProfileState,
    public languageState: LanguageState) {
    // console.log('isResidenceCountryInBlackList 1', this.isResidenceCountryInBlackList);
    this.isResidenceCountryInBlackList = this.geoState.isResidenceCountryInBlackList;
    this.isCitizenshipInBlackList = this.geoState.isCitizenshipInBlackList;
    // console.log('isCitizenshipInBlackList', this.isCitizenshipInBlackList);
    // console.log('isResidenceCountryInBlackList 2', this.isResidenceCountryInBlackList);
    fromMobx(() => this.geoState.isResidenceCountryInBlackList)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isResidenceCountryInBlackList => {
        this.isResidenceCountryInBlackList = isResidenceCountryInBlackList;
      });
    fromMobx(() => this.geoState.isCitizenshipInBlackList)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isCitizenshipInBlackList => {
        this.isCitizenshipInBlackList = isCitizenshipInBlackList;
        // console.log('isCitizenshipInBlackList', this.isCitizenshipInBlackList);
      });

    this.loadIsUserCompletedProfileFormData();
    // this.loadIsResidenceCountryInBlackListData();
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;

      });

    this.initializeReaction();
  }

  ngOnInit(): void {
    this.refreshDataAndSdk();
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();

    if (this.autorunDisposer) {
      this.autorunDisposer();
    }
  }

  public loadIsUserCompletedProfileFormData() {
    fromMobx(() => this.profileState.isUserCompletedProfileForm)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isUserCompletedProfileForm = data;
        // console.log('isUserCompletedProfileForm 2', this.isUserCompletedProfileForm);
      });
  }

  public getKycStatus() {
    this.dataService.getUserKycStatus()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: any) => {
        // console.log(res);
        this.isUploadedImages = res.isUploaded;
        this.kycStatus = res.status;
        this.isSpinnedStatus = false;
      }, () => {
        // console.log(error1);
        this.isSpinnedStatus = false;
      });
  }

  public submitStartVerification() {
    if (!this.isResidenceCountryInBlackList && !this.isCitizenshipInBlackList
      && this.isUserCompletedProfileForm
      && this.kycStatus === 0 && !this.isUploadedImages) {
      this.onStartClicked.emit(true);
    }
  }

  // public loadIsResidenceCountryInBlackListData() {
  //   this.isResidenceCountryInBlackList = this.dataService.getIsResidenceCountryInBlackList();
  //   // console.log('isResidenceCountryInBlackList 3', this.isResidenceCountryInBlackList);
  //   this.dataService.getIsResidenceCountryInBlackListEmitter()
  //     .pipe(takeUntil(this.destroySubject$))
  //     .subscribe(data => {
  //       this.isResidenceCountryInBlackList = data;
  //       // console.log('isResidenceCountryInBlackList 4', this.isResidenceCountryInBlackList);
  //     });
  // }

  /*
  public launchWebSdk(apiUrl, flowName, accessToken, applicantEmail, applicantPhone) {
    let snsWebSdkInstance = snsWebSdk.Builder(apiUrl, flowName)
        .withAccessToken(
            accessToken,
            (newAccessTokenCallback) => {
                // Access token expired
                // get a new one and pass it to the callback to re-initiate the WebSDK
                let newAccessToken = '...'; // get a new token from your backend
                newAccessTokenCallback(newAccessToken)
            }
        )
        .withConf({
            lang: 'en',
            email: applicantEmail,
            phone: applicantPhone,
            onMessage: (type, payload) => {
                // see below what kind of messages the WebSDK generates
                //console.log('WebSDK onMessage', type, payload)
            },
            onError: (error) => {
                // console.error('WebSDK onError', error)
            },
        })
        .build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container')
  }
   */

  public launchWebSdk(accessToken, applicantEmail, applicantPhone, preferredLanguage) {
    this.getTimestampLog('Sumsub websdk is loading');
    const snsWebSdkInstance = snsWebSdk.init(
      accessToken,
      () => this.getNewAccessToken()
    ).withConf({
      lang: preferredLanguage,
      email: applicantEmail,
      phone: applicantPhone,
    })// see below what kind of messages WebSDK generates
      .on('idCheck.onReady', (_payload) => {
        this.showLoader = false;
        this.getTimestampLog('Sumsub iframe ready');
      })
      .on('idCheck.stepCompleted', (payload) => {
        console.log('stepCompleted', payload);
      })
      .on('idCheck.onError', (error) => {
        console.log('onError', error);
      }).build();

    this.getTimestampLog('Launching sumsub iframe');
    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container');
  }

  async getNewAccessToken(): Promise<any> {
    this.getTimestampLog('New sumsub token has been requested');
    return new Promise((resolve, reject) => {
      this.dataService.getSumsubAccessToken(this.userEmail, 1200).subscribe(data => {
        resolve(data);
      }, error => reject(error));
    });
  }

  getTimestampLog(message) {
    console.log(message, new Date().toLocaleString());
  }

  getPreferredLanguage(currentLanguage) {
    switch (currentLanguage) {
      case 'en':
        this.preferredLanguage = 'en';
        break;
      case 'ru':
        this.preferredLanguage = 'ru';
        break;
      case 'kz':
        this.preferredLanguage = 'ru';
        break;
      case 'ch':
        this.preferredLanguage = 'zh';
        break;
      default:
          this.preferredLanguage = 'en';
    }
  }
  public refreshDataAndSdk() {
    this.getPreferredLanguage(localStorage.getItem('preferred_lang'));
    this.profileState.loadUserProfileIfEmpty();

    this.getKycStatus();

    fromMobx(() => this.profileState.userProfile)
      .pipe(takeUntil(this.destroySubject$), filter((res) => !!res))
      .subscribe((profileResult: MyProfileInterface) => {
        this.getTimestampLog('After profile is loaded');
        const splitedEmail = profileResult.email.split('@');
        this.userEmail = `${splitedEmail[0]}-at-${splitedEmail[1]}`;
        this.dataService.getSumsubAccessToken(this.userEmail, 1200)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe((sumsubResult) => {
            this.getTimestampLog('Sumsub token received');
            const { token } = sumsubResult;
            const applicantEmail = '';
            const applicantPhone = '';
            this.launchWebSdk(token, applicantEmail, applicantPhone, this.preferredLanguage);
            //this.launchWebSdk(apiUrl, flowName, token, applicantEmail, applicantPhone);
          });
      });
  }
  private initializeReaction() {
      this.autorunDisposer = reaction(
        () => this.languageState.currentLanguage,
        (currentLanguage) => {
          console.log('Language has changed:', currentLanguage);
          this.refreshDataAndSdk();
        }
      );
  }
}
