import {AfterViewInit, Component, OnInit} from '@angular/core';
import {filter, map, pluck, switchMap, tap} from 'rxjs/operators';
import {FormBuilder, FormControl, NgModel, Validators} from '@angular/forms';
import {DataService} from '../../../services/data.service';
import {ActivatedRoute} from '@angular/router';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {MatDialog} from '@angular/material/dialog';
import {WithdrawalComponent} from '../../../dialogs/withdrawal/withdrawal.component';
import {WithdrawalSuccessComponent} from '../../../dialogs/withdrawal-success/withdrawal-success.component';
import {WalletBalanceInterface} from '../../../models/wallet-balance.model';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {WalletDepositComponent} from '../../../dialogs/wallet-deposit/wallet-deposit.component';
import {HttpClient} from '@angular/common/http';
import {balance} from '../bcxg/mock';
import {Router} from '@angular/router';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss', '../common-styles/form-with-table.scss']
})
export class WithdrawComponent implements OnInit, AfterViewInit {
  public isWalletWorking: boolean;

  currencyOptions$ = this.dataService.currencyOptions$.pipe(
    map((data) => data.map((v) => (
      {...v, value: v.value.currency}
    )))
  );

 displayAll$ = new BehaviorSubject(false);

  coinControl = new FormControl({
    value: null,
  }, Validators.required);

  minWithdrawSumm = 0;
  currentInputValue = 0;
  currentInputTagValue: string;
  token;
  availableSum;
  localStorageAvailableSum;
  ada;
  btc;
  bch;
  eth;
  xrp;
  usdt;

  data$ = combineLatest([this.dataService.getWithdrawals(), this.coinControl.valueChanges, this.displayAll$]).pipe(
    map(([data, coin, displayAll]) => data.filter((v) => v.currency === coin || displayAll)),
  );

  selectedBalance: WalletBalanceInterface;
  selectedFee: number;

  selectedBalance$ = combineLatest([this.dataService.currencyOptions$, this.coinControl.valueChanges]).pipe(
    map(([options, coin]) => options.find((op) => op.value.currency === coin)?.value),
    tap((v) => this.selectedBalance = v),
  );

  selectedFee$ = this.coinControl.valueChanges.pipe(
    filter((currency) => !!currency),
    switchMap(
      (currency) => this.dataService.getWalletFeeCurrency(currency)
    ),
    map((data) => +data.res.biteeuFee + +data.res.vendorFee),
    tap((v) => this.selectedFee = v),
  );

  model = this._fb.group({
    coin: this.coinControl,
    address: ['', Validators.required],
    tag: [''],
    amount: ['', Validators.required],
  });

  get amount() {
    return this.model.get('amount').value;
  }

  set amount(v: number) {
    this.model.get('amount').patchValue(v);
  }

  constructor(
    public dataService: DataService,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _dialog: MatDialog,
    private http: HttpClient,
    public router: Router
  ) { }

  openDialog() {
    this._dialog.open(WithdrawalComponent, {
      data: {
        dialogFor: 'withdraw-confirmation',
        currency: this.coinControl.value,
        walletBalance: this.selectedBalance,
        address: this.model.value?.address,
        amount: this.model.value?.amount,
        network: this.selectedBalance?.network,
        fee: this.selectedFee,
        tag: this.currentInputTagValue,
      }
    })
      .afterClosed().subscribe(res => {
      if (res && res.success) {
        this._dialog.open(WithdrawalSuccessComponent);
        if (this.availableSum) {
          this.availableSum = this.availableSum - this.currentInputValue;
        } else {
          this.availableSum = this.localStorageAvailableSum - this.currentInputValue;
        }
      }
    }, err => {
      console.error(err);
    });
  }

  public openDeposit(key, currency, tag) {
    this._dialog.open(WalletDepositComponent,
      {data: {key, currency, tag, blockedMarkets: []}});
  }

  ngOnInit() {
    this.dataService.getMinimalCurrencyAmount('ADA').subscribe(v => this.ada = v.res.minWithdrawAmt);
    this.dataService.getMinimalCurrencyAmount('BTC').subscribe(v => this.btc = v.res.minWithdrawAmt);
    this.dataService.getMinimalCurrencyAmount('BCH').subscribe(v => this.bch = v.res.minWithdrawAmt);
    this.dataService.getMinimalCurrencyAmount('ETH').subscribe(v => this.eth = v.res.minWithdrawAmt);
    this.dataService.getMinimalCurrencyAmount('XRP').subscribe(v => this.xrp = v.res.minWithdrawAmt);
    this.dataService.getMinimalCurrencyAmount('USDT').subscribe(v => this.usdt = v.res.minWithdrawAmt);
    this.dataService.getBalances()
      .subscribe((data: Array<WalletBalanceInterface>)  => {
        this.isWalletWorking = data.every((bal: WalletBalanceInterface) => bal.state === 'working');
        if (!this.isWalletWorking) {
          this.router.navigate(['/user-settings/wallets']);
        }

      });
  }

  ngAfterViewInit(): void {
    this.coinControl.setValue(this._route.snapshot.params.currency);
    this.coinControl.valueChanges.subscribe((newValue) => {
        this.getMinWithdrawalSumm(newValue);
        // const currencyValue = this.http.get<any>(`https://api.intebix.kz/api/v1/user/withdrawals/minimal-amount?currency=${newValue}`);
        //
        // currencyValue.subscribe((data) => {
        //   this.minWithdrawSumm = data.res.minWithdrawAmt;
        // }, (error) => {
        //   console.error('Error fetching data:', error);
        // });
    });
    // if (this._route.snapshot.params.currency) {
    //   this.coinControl.disable();
    // }
  }

  getMinWithdrawalSumm(currentCoin) {
    switch (currentCoin) {
      case 'ADA':
        this.minWithdrawSumm = this.ada;
        break;
      case 'BTC':
        this.minWithdrawSumm = this.btc;
        break;
      case 'BCH':
        this.minWithdrawSumm = this.bch;
        break;
      case 'ETH':
        this.minWithdrawSumm = this.eth;
        break;
      case 'XRP':
        this.minWithdrawSumm = this.xrp;
        break;
      case 'USDT':
        this.minWithdrawSumm = this.usdt;
        break;
      default:
        this.minWithdrawSumm = undefined;
    }
  }

  onInputChange(event: any) {
    this.currentInputValue = event.target.value;
  }

  formatNumber(number: number) {
    const numberString = number.toString();

    if (numberString.includes('+')) {
      return number;
    } else if (numberString.indexOf('.') === -1) {
      return number;
    } else {
      const decimalPart = numberString.split('.')[1];
      if (decimalPart.length > 8) {
        return number.toFixed(8);
      }
    }
    return number;
  }

  onInputTagChange(event: any) {
    this.currentInputTagValue = event.target.value;
  }

  protected readonly NgModel = NgModel;
}
