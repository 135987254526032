import {Component, OnDestroy, OnInit} from '@angular/core';
import { DataService } from '../../../services/data.service';
import {AuthService} from '../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {MatDialog} from '@angular/material/dialog';
import {NotVerifiedComponent} from '../../../dialogs/not-verified/not-verified.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-my-activity',
  templateUrl: './my-activity.component.html',
  styleUrls: ['./my-activity.component.scss']
})
export class MyActivityComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public logs;
  public email;
  public isVisibleActivity = true;
  public dailyWithdrawalLimit;
  public isSpinnedStatus = true;
  public kycStatus: number;
  public isUploadedImages: number;
  public notVerifiedOpen = false;
  
  destroySubject$: Subject<void> = new Subject();

  constructor(private dataService: DataService,
              public authService: AuthService,
              private router: Router,
              public dialog: MatDialog) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }
  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.dataService.getUserLogs()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          this.logs = res.sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at));
        });
      this.dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          this.email = res.email;
          this.dailyWithdrawalLimit = '0 BTC';
        });
      this.getKycStatus();
    }
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public giveMeName(activity) {
    switch (activity) {
      case 'LOGGINED':
        return 'Login';
      case 'PASSWORD_WAS_CHANGED' :
        return 'Change password';
      default: return activity;
    }
  }

  public getKycStatus() {
    this.dataService.getUserKycStatus()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: any) => {
        this.kycStatus = res.status;
        this.isSpinnedStatus = false;
        this.isUploadedImages = res.isUploaded;
      }, () => {
        // console.log(error1);
        this.isSpinnedStatus = false;
      });
  }

  public openNotVerified() {
    this.dialog.open(NotVerifiedComponent).afterClosed()
      .subscribe(() => {
        this.router.navigateByUrl('user-settings/identity-verification').then();
      });
    this.notVerifiedOpen = true;
  }

}
