import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {Router, NavigationEnd} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {CurrencyLastPriceClass} from '../../models/currency-last-price.class';
import {Subject} from 'rxjs/internal/Subject';
import {ProfileState} from '../../store/profile.state';
import {ThemeService} from '../../services/theme.service';
import {LanguageState} from '../../store/language.state';
import {Location} from '@angular/common';
import {MobileApplicationState} from '../../store/mobile-application.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public isLightTheme = false;
  public isLandingPage: boolean;
  tickersIsOpened = false;
  public openList = { profile: false, lang: false, account: false };
  public headerData: any = null;
  public isMarketPage: boolean;
  public isUserSettingsPage: boolean;
  public isPairPage: boolean;
  public isBuyCryptoPage: boolean;
  public isGlobalMessageHidden = true;
  public currentLang: string;
  // public toggleHeaderPair: boolean = false
  // public reCaptcha_version = RECAPTCHA_VERSION;
  public reCaptcha_version = environment.name || 'prod';
  public url = environment.baseURL;
  public intervalUpdateWallets;
  сurrencyLastPrice: CurrencyLastPriceClass;
  lastPrice: number;
  destroySubject$ = new Subject();
  @Output() menuOpenedEvent = new EventEmitter<boolean>();
  menuOpened = false;
  isMobileApplication: boolean;

  constructor(
    public dataService: DataService,
    public profileState: ProfileState,
    public authService: AuthService,
    public router: Router,
    private translateService: TranslateService,
    public themeService: ThemeService,
    public languageState: LanguageState,
    public mobileApplicationState: MobileApplicationState,
  ) {}
  @ViewChild('aside', { static: false }) aside: ElementRef;
  @ViewChild('menuspan', { static: false }) menuspan: ElementRef;
  // @ViewChild('extraInfo', { static: false }) extraInfo: ElementRef;
  @HostListener('window:click', ['$event'])
  closeOpenedLists(event: MouseEvent): void {
    if (!event.target['classList'].contains('profile')) {
      this.openList.profile = false;
    } else if (!event.target['classList'].contains('lang')) {
      this.openList.lang = false;
    }
  }

  togglePairPage() {
    // this.toggleHeaderPair = !this.toggleHeaderPair
    // this.extraInfo.nativeElement.classList.toggle('extra_info_expanded')
  }

  openMenu() {
    this.menuOpened = !this.menuOpened;
    this.menuOpenedEvent.emit(this.menuOpened);
  }

  toggle() {
    this.aside.nativeElement.classList.toggle('aside_menu');
    this.menuspan.nativeElement.classList.toggle('rotateArrow');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isMobileApplication = this.mobileApplicationState.getMobileApplicationState();
    }, 500);
  }

  ngOnInit(): void {
    this.isLandingPage = this.router.url.includes('/landing') || this.router.url === '/';

    this.currentLang = this.translateService.currentLang;
    this.url = this.prepareBiteeuUrl('' + this.reCaptcha_version, this.currentLang || '');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isLandingPage = this.router.url.includes('/landing') || this.router.url === '/';

        if (event.url.includes('/trades')) {
          this.getMarketSummary();
        }
      }
    });
    this.isPairPage = this.router.url.includes('/trades');
    this.getMarketSummary();

    this.router.events.forEach(() => {
      this.checkForLanguageFromUrl(this.router.url);
      this.isMarketPage = this.router.url.includes('/markets');
      this.isUserSettingsPage = (this.router.url.indexOf('user-settings') >= 0);
      this.isPairPage = this.router.url.includes('/trades');
      this.isBuyCryptoPage = this.router.url.includes('/buy-crypto');
    }).then();
    this.getMessageTypeForGlobalMessage();
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .subscribe(data => {
        this.isLightTheme = data;
      });


    if (this.authService.isLoggedIn) {
      this.getBalances();
      this.intervalUpdateWallets = setInterval(() => {
        // const userId = this.dataService.getUserId() ? this.dataService.getUserId() : +localStorage.getItem('visible-set');
        this.postUpdateWallets(this.profileState.userId);
      }, 300000);
    }

    this.dataService.getMarketPairNewEmitter()
      .subscribe(() => {
        this.getMarketSummary();
      });
    this.getСurrencyLastPrice();
  }

  ngOnDestroy() {
    if (this.authService.isLoggedIn) {
      clearInterval(this.intervalUpdateWallets);
    }
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  public getBalances() {
    this.dataService.SubscribeToUserBalances().pipe(
      takeUntil(this.destroySubject$)
    ).subscribe(socketData => {
      this.profileState.setUserBalance(socketData);
    });
  }


  public postUpdateWallets(userId: number) {
    this.dataService.postUpdateWallets(userId)
      .subscribe(() => {},
        error => {
          console.log(error);
        });
    this.dataService.postUpdateWithdrawalStatus(userId)
      .subscribe(() => {},
        error1 => {
          console.log(error1);
        });
    this.dataService.postCheckPendingWalletsStatus(userId)
      .subscribe(() => {},
        error2 => {
          console.log(error2);
        });
  }

  public getMessageTypeForGlobalMessage() {
    let messageType = this.dataService.getMessageTypeForGlobalMessage();
    if (messageType) {
      this.isGlobalMessageHidden = false;
    }
    this.dataService.getMessageTypeGlobalMessageEmitter()
      .subscribe(data => {
        messageType = data;
        if (messageType) {
          this.isGlobalMessageHidden = false;
        }
      });
  }

  private getMarketSummary() {
    const pair = this.dataService.getPair();
    this.dataService.currentMarketDetails$
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data) => {
        const headerData = data;
        this.headerData = {
          BaseVolume24: headerData?.BaseVolume24 || 0,
          BaseVolumeUSDT: headerData?.BaseVolumeUSDT || 0,
          LastPriceUSDT: headerData?.LastPriceUSDT || 0,
          Created: headerData?.Created || 0,
          High24: headerData?.High24 || 0,
          Low24: headerData?.Low24 || 0,
          MarketName: headerData?.MarketName || 0,
          PrevDay: headerData?.PrevDay || 0,
          Last24: headerData?.Last || 0,
          LastUp: headerData?.LastUp,
          LastDown: headerData?.LastDown,
          percentChange: headerData?.percentChange || 0,
          CurrencyBase: headerData?.CurrencyBase || '',
          Change: headerData?.Change || '',
          UsdPrice: 30000,
          lastUp: headerData?.lastUp || false,
          lastDown: headerData?.lastDown || false,
        } as any;
/*
        console.log('header data', this.headerData, headerData)
*/
        const title = pair.split('-').join('/') + '  '
          + ((data && data.last) ? (+data.last).toFixed(8) : '');
        if (this.router.url.includes('/trades')) {
          window.document.title = title;
        }
      });

  }

  private getСurrencyLastPrice(): void {
    this.сurrencyLastPrice = this.dataService.getСurrencyLastPrice();
    this.lastPrice = this.сurrencyLastPrice ? this.сurrencyLastPrice.lastPrice : undefined;
    this.dataService.getСurrencyLastPriceEmitter()
      .subscribe((сurrencyLastPrice: CurrencyLastPriceClass) => {
        this.сurrencyLastPrice = сurrencyLastPrice;
        this.lastPrice = сurrencyLastPrice.lastPrice;
        const title = this.dataService.getPair().split('-').join('/') + '  '
          + ((this.lastPrice) ? (+this.lastPrice).toFixed(8) : '');
        if (this.router.url.includes('/trades')) {
          window.document.title = title;
        }
      });
  }

  public logout(): void {
    clearInterval(this.intervalUpdateWallets);
    this.authService.logout();
    localStorage.removeItem('selected_currency');
    this.router.navigate(['/login']).then();
  }

  public toggleList(list: string): void {
    this.openList[list] = !this.openList[list];
    console.log(this.openList);
  }

  public selectLanguage(lang: string): void {
    window.localStorage.setItem('preferred_lang', lang);
    this.translateService.use(lang);
    this.openList.lang = false;
    this.currentLang = lang;
    this.url = this.prepareBiteeuUrl('' + this.reCaptcha_version, this.currentLang || '');
    this.languageState.setLanguage(lang);
  }

  public prepareBiteeuUrl(reCaptcha_version: string, language: string): string {
    let languageUrl = '';
    let biteeuUrl = '';
    switch (reCaptcha_version) {
      case 'dev':
      case 'prod':
        biteeuUrl = environment.baseURL;
        break;
      case 'auprod':
      case 'audev':
        biteeuUrl = environment.auURL;
        break;
      default: biteeuUrl = environment.baseURL;
    }
    switch (language) {
      case 'en':
        languageUrl = 'index.html';
        break;
      case 'tr':
        languageUrl = 'index_tu.html';
        break;
      case 'ru':
        languageUrl = 'index_ru.html';
        break;
      case 'zh':
        languageUrl = 'index_ch.html';
        break;
      default: languageUrl = 'index.html';
    }
    return biteeuUrl + languageUrl;
  }

  public checkForLanguageFromUrl(url: string) {
    const urlArray = url.split('/');
    const language = urlArray[urlArray.length - 1];
    switch (language) {
      case 'en':
      case 'ru':
      case 'zh':
      case 'tr':
        this.selectLanguage(language);
        break;
    }
  }
}
