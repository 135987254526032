export const apiMethods = [
  {
    id: 1,
    method: 'https://api.biteeu.com/api/v1/analytics/summary',
    description: 'The summary endpoint is to provide an overview of market data for all tickers and all market pairs on the exchange',
    type: 'GET',
  },
  {
    id: 2,
    method: 'https://api.biteeu.com/api/v1/analytics/assets',
    description: 'The assets endpoint is to provide a detailed summary for each currency available on the exchange',
    type: 'GET',
  },
  {
    id: 3,
    method: 'https://api.biteeu.com/api/v1/analytics/ticker',
    description: 'The ticker endpoint is to provide a 24-hour pricing and volume summary for each market pair available on the exchange',
    type: 'GET',
  },
  {
    id: 4,
    method: 'https://api.biteeu.com/api/v1/analytics/orderbook/:marketPair',
    description: 'The order book endpoint is to provide a complete level 2 order book (arranged by best asks/bids) with full depth returned for a given market pair',
    type: 'GET',
  },
  {
    id: 5,
    method: 'https://api.biteeu.com/api/v1/analytics/trades/:marketPair',
    description: 'The trades endpoint is to return data on all recently completed trades for a given market pair',
    type: 'GET',
  },
];
