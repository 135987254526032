import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SvgService} from '../../services/svg.service';
import {DataService} from '../../services/data.service';
import {CurrencyInterface} from '../../models/currency.model';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {ProfileState} from '../../store/profile.state';
import {fromMobx} from '../../store/rx-from-mobx';

@Component({
  selector: 'app-add-wallet',
  templateUrl: './add-wallet.component.html',
  styleUrls: ['./add-wallet.component.scss']
})
export class AddWalletComponent implements OnInit {
  public selectedCurrency: string;
  public isPending = false;
  public isUserCompletedProfileForm = false;
  public currenciesList: Array<CurrencyInterface>; // new
  public messages = {
    requestSent: false,
    walletExists: false,
    walletInactive: false
  };
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(private dialogRef: MatDialogRef<AddWalletComponent>,
              public svgService: SvgService,
              public profileState: ProfileState,
              private dataService: DataService) {
    this.loadIsUserCompletedProfileFormData();
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  ngOnInit() {
    this.profileState.loadUserProfileIfEmpty();
    this.getCurrenciesList();
  }
  public getCurrenciesList() {
    this.currenciesList = this.dataService.getSharedCurrenciesList();
    this.dataService.getSharedCurrenciesListEmitter()
      .subscribe((response: Array<CurrencyInterface>) => {
        this.currenciesList = response;
      });
  }

  public close() {
    setTimeout(() => {
      this.postUpdateWallets(this.profileState.storagedUserId);
    }, 10000);

    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dialogRef.close();
  }
  private clearMessages() {
    Object.keys(this.messages).forEach(v => this.messages[v] = false);
  }
  public addWallet(currency) {
    if (this.isUserCompletedProfileForm) {
      const currencyItem = this.currenciesList.find(item => item.name === currency);

      if (currencyItem && currencyItem.status && currencyItem.status === 'Normal') {
        this.isPending = true;
        this.clearMessages();
        this.dataService.getWallet(currency).subscribe(
          () => {
            this.messages.requestSent = true;
            this.isPending = false;

            this.postUpdateWallets(this.profileState.storagedUserId);

            this.profileState.setWalletToCheck(currency);

            }, err => {
            console.log(err.error.errorCode);
            switch (err.error.errorCode) {
              case '417_65': {
                this.messages.walletExists = true;
                break;
              }
              default: break;
            }
            this.isPending = false;
          }
        );
      } else {
        this.clearMessages();
        this.messages.walletInactive = true;
      }
    }
  }

  private postUpdateWallets(userId: number) {
    this.dataService.postUpdateWallets(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        },
        error => {
          console.log(error);
        });
    this.dataService.postCheckPendingWalletsStatus(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        },
        error2 => {
          console.log(error2);
        });
  }

  public loadIsUserCompletedProfileFormData() {
    fromMobx(() => this.profileState.isUserCompletedProfileForm)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isUserCompletedProfileForm = data;
        // console.log('isUserCompletedProfileForm 2', this.isUserCompletedProfileForm);
      });
  }

}
