import {Component, Inject, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-kyc-modal',
  templateUrl: './kyc-modal.component.html',
  styleUrls: ['./kyc-modal.component.scss']
})
export class KycModalComponent implements OnDestroy {
  private customRedirect: boolean;
  public successMessage = '';
  public errorMessage = '';
  public isSpinnedStatus = true;
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(public dialogRef: MatDialogRef<KycModalComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private authService: AuthService,
              private router: Router) {
    this.resetErrors();
    const token = this.data.token;
    if (token) {
      switch (this.data.action) {
        case 'approve': {
          this.isSpinnedStatus = true;
          this.authService.changeUserKycStatus('approve', token)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              this.successMessage = 'approved';
              this.handleSuccess();
            }, err => this.handleError(err));
          break;
        }
        case 'reject': {
          this.isSpinnedStatus = true;
          this.authService.changeUserKycStatus('reject', token)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              this.successMessage = 'rejected';
              this.handleSuccess();
            }, err => this.handleError(err));
          break;
        }
        case 'block': {
          this.isSpinnedStatus = true;
          this.authService.changeUserKycStatus('block', token)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              this.successMessage = 'blocked';
              this.handleSuccess();
            }, err => this.handleError(err));
          break;
        }
      }
    }

    this.dialogRef.afterClosed()
      .subscribe(() => {
        if (!this.customRedirect) {
          this.router.navigate(['/markets']).then();
        }
      });

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  private handleSuccess(): void {
    this.isSpinnedStatus = false;
  }
  private handleError(err): void {
    this.isSpinnedStatus = false;
    console.log(err);
    this.errorMessage = '';
    if (err.error) {
      switch (err.error.errorCode) {
        case '418_78':
          this.errorMessage = err.error.message;
          break;
        default:
          this.errorMessage = err.error.message || err.message;
          break;
      }
    }
  }
  private resetErrors() {
    this.successMessage = '';
    this.errorMessage = '';
  }

}
