import { Component } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ThemesModel } from '../../../models/themes.model';
import { SvgService } from '../../../services/svg.service';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss']
})
export class ThemeSwitcherComponent {
  public active = false;
  public themesModel = ThemesModel;

  constructor(
    public svgService: SvgService,
    public dataService: DataService
  ) {}

  public toggleClass(): void {
    this.active = !this.active;
  }

  selectTheme(theme: string) {
    this.dataService.currentTheme = theme;
    localStorage.setItem('currentTheme', theme);
    this.dataService.currentThemeSubject.next(theme);
  }
}
