import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {SvgService} from '../../services/svg.service';
import {ProfileState} from '../../store/profile.state';

@Component({
  selector: 'app-wallet-created',
  templateUrl: './wallet-created.component.html',
  styleUrls: ['./wallet-created.component.scss']
})
export class WalletCreatedComponent implements OnInit {
  public isLightTheme = false;
  public currency = '';
  destroySubject$: Subject<void> = new Subject();

  constructor(private dialogRef: MatDialogRef<WalletCreatedComponent>,
              public svgService: SvgService,
              public profileState: ProfileState,
              private dataService: DataService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.currency = this.profileState.walletToCheck;
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  public ngOnInit() {
    this.profileState.setWalletToCheck('');
  }

  public close() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dialogRef.close();
  }


}
