import { ShippingDetails } from "./shipping-details.model";

export enum BCXGRedemptionStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    CANCELED_BY_USER = 'CANCELED BY USER',
    CANCELED_BY_ADMIN = 'CANCELED BY ADMIN',
    APPROVED = 'APPROVED'
}

export class BCXGRedemption {
    id: number;
    type: string;
    date: Date;
    value: number;
    status?: BCXGRedemptionStatus;
    paymentOption: string;
    shippingDetails?: ShippingDetails;

    constructor(
        id: number,
        type: string,
        date: Date,
        value: number,
        paymentOption: string,
        status?: BCXGRedemptionStatus,
        shippingDetails?: ShippingDetails,
    ) {
        this.id = id;
        this.type = type;
        this.date = date;
        this.value = value;
        this.status = status;
        this.shippingDetails = shippingDetails;
        this.paymentOption = paymentOption;
    }
}
