import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {catchError, takeUntil} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {of} from 'rxjs/internal/observable/of';
import { MatDialog } from '@angular/material/dialog';
import { InfoComponent } from 'src/app/dialogs/info/info.component';
import { BalanceInterface } from 'src/app/models/balance.model';

type StepType = 'SELECT_TYPE' | 'BUY';

@Component({
  selector: 'app-buy-td-bonuses',
  templateUrl: './buy-td-bonuses.component.html',
  styleUrls: ['./buy-td-bonuses.component.scss']
})
export class BuyTDBonusesComponent implements OnInit, OnDestroy {

  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();

  currentStep: StepType = 'BUY';

  public isProdEnvironment = environment.name === 'prod';

  formData = {
    cryptoCurrency: 'USDT',
    amount: null,
    balance: 0,
  };

  bankForm: string = null;
  waitForBankForm = false;
  isBankFormLoadingError = false;

  waitForCheck = false;

  buyPrice: number;

  contractId: string;
  clientStatus: string;

  get bonusesToCrypto() {
    if (!this.formData.amount || !this.buyPrice) { return null; }
    return (+(this.formData.amount / this.buyPrice).toFixed(2)).toString();
  }

  get bonusesToCryptoTotal() {
    if (!this.formData.amount) { return null; }
    return (+(+this.bonusesToCrypto * 1.01).toFixed(2)).toString();
  }

  constructor(public router: Router,
              public dataService: DataService,
              private dialog: MatDialog,
    ) {
      if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
      this.dataService.getIsLightThemeEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(isLightTheme => {
          this.isLightTheme = isLightTheme;
        });
    }

  ngOnInit(): void {
    this.loadPrices();
    this.dataService.getBalances()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((data: Array<BalanceInterface>) => {
          this.formData.balance = data.find(balance => balance.currency === this.formData.cryptoCurrency).balance;
        });
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }

  loadPrices(): void {
    this.dataService.getExchangeRate('BUY').subscribe(price => this.buyPrice = price);
  }

  submitForm(): void {
    this.waitForCheck = true;
    this.dataService
      .checkTDBonuses(this.formData.amount)
      .pipe(
        takeUntil(this.destroySubject$),
        )
      .subscribe(state => {
        this.clientStatus = state.clientStatus;
        this.contractId = state.contractId;

        if (this.clientStatus === 'NOT_VERIFIED') {
          this.dialog.open(InfoComponent, {
            data: {
              reason: 'td-bonuses-buy-not-verified', message: 'Пользователь не найден в системе Технодом'
            }
          }).afterClosed().toPromise().then(
            () => {
              window.location.href = '/profile';
            }
          );
    
          return;
        }

        this.pay(this.contractId);

      })
      .add(() => this.waitForCheck = false);
  }
  // build ready, again
  pay(contractId: string): void {
    this.dataService
      .payForTDBonuses(contractId, this.bonusesToCryptoTotal)
      .pipe(
        takeUntil(this.destroySubject$),
        catchError(error => {
          return of();
        })
      )
      .subscribe(() => {
        this.dialog.open(InfoComponent, {
          data: {
            reason: 'td-bonuses-buy-success', message: ''
          }
        }).afterClosed().toPromise().then(
          () => {
            window.location.href = '/wallets';
          }
        );
      });
  }

  next(form?: NgForm): void {
    if (!form.valid) { return; }

    this.submitForm();
  }
}
