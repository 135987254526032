import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, filter: any, searchText?: any, favMarkets?: any): any {
    let returnVal = [];
    if (filter === 'active')
      for (let i = 0, j = 0; i < value.length; i++) {
        let touched = false;
        for (const key in value[i]) {
          if (key !== 'name') {
            if (value[i][key].IsActive) {
              if (!touched) {
                returnVal[j] = {};
                touched = true;
              }
              returnVal[j][key] = value[i][key];
            }
          }
        }
        if (returnVal[j]) {
          returnVal[j].name = value[i].name;
          j++;
        }
      }
    else if (filter === 'fav') {
      for (let i = 0, j = 0; i < value.length; i++) {
        let touched = false;
        for (const key in value[i]) {
          if (key !== 'name') {
            if (favMarkets.includes(`${key}-${value[i].name}`)) {
              if (!touched) {
                returnVal[j] = {};
                touched = true;
              }
              returnVal[j][key] = value[i][key];
            }
          }
        }
        if (returnVal[j]) {
          returnVal[j].name = value[i].name;
          j++;
        }
      }
    } else returnVal = value;
    if (searchText) {
      searchText = searchText.toLowerCase();
      returnVal = returnVal.filter((val) => {
        return val.name.toLowerCase().includes(searchText);
      });
    }
    return returnVal;
  }

}
