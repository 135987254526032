import {Component, OnDestroy, OnInit} from '@angular/core';
import { DataService } from '../../../services/data.service';
import { TwoFactorAuthModalComponent } from '../../../dialogs/two-factor-auth/two-factor-auth-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {CurrencyInterface} from '../../../models/currency.model';
import {AuthService} from '../../../services/auth.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-withdrawal-whitelist',
  templateUrl: './withdrawal-whitelist.component.html',
  styleUrls: ['./withdrawal-whitelist.component.scss']
})
export class WithdrawalWhitelistComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public enableWithdrawalWhiteList: boolean;
  public withdrawalWhiteLIst;
  // public currencyList; // old
  public currenciesList: Array<CurrencyInterface>; // new
  public selectedCurrency: string;
  public adderss: string;
  public errors = {
    tFaInvalid: false
  };
  destroySubject$: Subject<void> = new Subject();

  constructor(private dataService: DataService,
              public authService: AuthService,
              private dialog: MatDialog) {
    if (this.authService.isLoggedIn) {
      this.dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          if (res['2fa_enabled']) {
            this.enableWithdrawalWhiteList = true;
          }
        });
      // this.currencyList = this.dataService.getCurrencyList();
    }
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    this.getWithdrawalWhiteList();
    this.getCurrenciesList();
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public getCurrenciesList() {
    this.currenciesList = this.dataService.getSharedCurrenciesList();
    if (!this.currenciesList) {
      this.dataService.getCurrenciesListFromServer();
    }
    this.dataService.getSharedCurrenciesListEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((response: Array<CurrencyInterface>) => {
        this.currenciesList = response;
      });
  }

  private getWithdrawalWhiteList(): void {
    this.dataService.getWithdrawalWhiteList()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        this.withdrawalWhiteLIst = res;
      });
  }
  public addToWithdrawalWhiteList(): void {
    this.clearErrors();
    this.dialog.open(TwoFactorAuthModalComponent)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          const addData = {
            public_key: this.adderss,
            currency: this.selectedCurrency,
            code: res.code
          };
          this.dataService.addToWithdrawalWhiteList(addData)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              this.getWithdrawalWhiteList();
            }, err => this.handleError(err));
        }
      }, err => this.handleError(err));
  }
  public removeFromWithdrawalWhiteList(key): void {
    this.clearErrors();
    this.dialog.open(TwoFactorAuthModalComponent)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          const removeData = {
            'public_key': key,
            'code': res.code
          };
          this.dataService.removeFromWithdrawalWhiteList(removeData)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              this.getWithdrawalWhiteList();
            }, err => this.handleError(err));
        }
      }, err => this.handleError(err));
  }
  private clearErrors() {
    Object.keys(this.errors).forEach(v => this.errors[v] = false);
  }
  private handleError(err) {
    if (err.error.errorCode === '401_7') {
      this.errors.tFaInvalid = true;
    }
  }
}
