import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit} from '@angular/core';
import {SvgService} from '../../../services/svg.service';
import {AuthService} from '../../../services/auth.service';
import {DataService} from '../../../services/data.service';
import {Compiler} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {ThemesModel} from '../../../models/themes.model';
import {CurrencyLastPriceClass} from '../../../models/currency-last-price.class';
import {ProfileState} from '../../../store/profile.state';
import {fromMobx} from '../../../store/rx-from-mobx';
import {MobileApplicationState} from '../../../store/mobile-application.state';

@Component({
  selector: 'app-tickers',
  templateUrl: './tickers.component.html',
  styleUrls: ['./tickers.component.scss']
})
export class TickersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  @Output() public close = new EventEmitter<void>();
  public filterParam = 'all';
  public isFavoriteOnly = false;
  public searchText;
  public sortParams = {
    sortByName: 'up',
    sortByLast: '',
    sortByVolume: '',
    sortBy24: ''
  };
  public tickers;
  public userId = undefined;
  public favMarkets;
  public objectKeys = Object.keys;
  public ThemesModel = ThemesModel;
  public activeList = false;
  public selectedMarket = 'BTC';
  public listMarkets = ['ALL', 'BTC', 'USDT', 'ETH'];
  public currencyMain;
  public currencyAdditional;
  public pair = '';
  public isMobileApplication: boolean;
  private destroySubject$: Subject<void> = new Subject();

  constructor(
    public router: Router,
    public svgService: SvgService,
    public authService: AuthService,
    public profileState: ProfileState,
    public dataService: DataService,
    private _compiler: Compiler,
    public mobileApplicationState: MobileApplicationState
  ) {}

  ngOnInit() {
    this.isMobileApplication = this.mobileApplicationState.getMobileApplicationState();
    this.pair = this.dataService.market;
    this.currencyMain = this.dataService.market.split('-')[1];
    this.currencyAdditional = this.dataService.market.split('-')[0];
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.pair = this.dataService.market;
        this.currencyMain = this.dataService.market.split('-')[1];
        this.currencyAdditional = this.dataService.market.split('-')[0];
      });
    if (this.authService.isLoggedIn) {
      this.getUserId();
    }
    this.getTickHistory();
    this.getTickers();
    if (this.authService.isLoggedIn) {
      this.getFavoriteMarkets();
    }
    this.dataService.SubscribeToUpdateFavs()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: any) => {
        if (data.u) {
          this.getFavoriteMarkets();
        }
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  ngAfterViewInit() {
    if (this.mobileApplicationState.getMobileApplicationState() && document.querySelector('.cdk-overlay-pane')) {
      const cdkOverlayPaneElement = document.querySelector('.cdk-overlay-pane');
      cdkOverlayPaneElement.classList.add('mobile-application');
    }
  }

  toggleIsFavoriteOnly() {
    this.isFavoriteOnly = !this.isFavoriteOnly;

  }

  public getUserId() {
    this.profileState.loadUserProfileIfEmpty();
    fromMobx(() => this.profileState.storagedUserId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((userId) => this.userId = userId);
  }

  private getTickHistory() {
    this.dataService.getMarketsList()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        this.updateTickers(res);
      });
  }

  private getTickers() {
    this.dataService.SubscribeToSummaryDeltas()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res) => {
        this.updateTickers(res);

        const market = this.tickers.find(ticker => ticker.MarketName === `${this.currencyAdditional} - ${this.currencyMain}`);
        if (market && this.currencyAdditional && this.pair && this.currencyMain && market[this.currencyAdditional]) {
          const currencyLastPrice = new CurrencyLastPriceClass(
            this.pair,
            this.currencyMain,
            this.currencyAdditional,
            market.Last
          );
          this.dataService.setCurrencyLastPrice(currencyLastPrice);
          this.dataService.passCurrencyLastPrice(currencyLastPrice);
        }
      });
  }

  private updateTickers(res) {
    this.tickers = Array.from(res.reduce(
      (acc, ticker) => acc.set(ticker.MarketName.split('-')[1], {
          ...(acc.get(ticker.MarketName.split('-')[1]) || {}),
          name: ticker.MarketName.split('-')[1],
          [ticker.MarketName.split('-')[0]]: {
            Last: ticker.Last,
            BaseVolume: ticker.BaseVolume24,
            h24: ticker.percentChange,
          }
        }
      ), new Map()
    ).values());
  }

  private getFavoriteMarkets() {
    this.dataService.getFavoriteMarkets()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        this.favMarkets = res;
      });
  }

  public toggleFav(market, isFav) {
    const data = {market_name: market};
    if (isFav) {
      this.dataService.removeFromFavoriteMarkets(data)
        .subscribe();
    } else {
      this.dataService.addToFavoriteMarkets(data).subscribe();
    }
  }

  public toggleSort(param) {
    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = '';
    } else {
      this.sortParams[param] = 'up';
    }
  }

  public navigateToTrades(first, last): void {
    const pair = last + '-' + first.name;
    this.reloadDataForNewPairAtMarketPage(pair);
  }

  private reloadDataForNewPairAtMarketPage(pair: string) {
    this.dataService.passExchangeAmount(' ');
    this.dataService.setExchangeAmount(' ');
    this.dataService.passExchangePrice(' ');
    this.dataService.setExchangePrice(' ');
    try {
      this.dataService.leaveRoom(); //   trades
      this.dataService.leaveRoomUserId('' + this.userId); //   trades
      this.dataService.storePair(pair);
      this._compiler.clearCache();
      this.dataService.joinRoom(); //   trades
      if (this.authService.isLoggedIn) {
        this.profileState.loadUserProfileIfEmpty();
        fromMobx(() => this.profileState.storagedUserId)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe((userId) => this.dataService.joinRoomUserId('' + userId));
      }
      this.dataService.passMarketPairNew(pair); //   market-depth, order-book
      this.dataService.getMarketMinOrderAmount(); // exchange-calculator
    } catch (e) {
      // console.log(e);
    }
  }

  public toggleClass(): void {
    this.activeList = !this.activeList;
  }

  setMarket(market) {
    this.selectedMarket = market;
  }

  getLastUSDT(tickChild: string, last: number) {
    if (tickChild !== 'USDT') {
      const lastUSDT = this.tickers.find(ticker => ticker.name === tickChild)['USDT'].Last;

      return lastUSDT * last;
    }
  }
}
