import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pairMarketName'
})
export class PairMarketNameSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction) {
      return value.sort((a, b) => {
        if (direction === 'up') {
          if (a.MarketName < b.MarketName) {
            return 1;
          }
          if (a.MarketName > b.MarketName) {
            return -1;
          }
          return 0;
        } else if (direction === 'down') {
          if (a.MarketName < b.MarketName) {
            return -1;
          }
          if (a.MarketName > b.MarketName) {
            return 1;
          }
          return 0;
        }
      });
    }
    return value;
  }

}
