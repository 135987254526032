import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'triggerPriceSort'
})
export class TriggerPriceSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction) {
      return value.sort((a, b) => {
        if (direction === 'up') {
          return a.triggerPrice - b.triggerPrice;
        } else {
          return b.triggerPrice - a.triggerPrice;
        }
      });
    }
    return value;
  }

}
