import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort24h'
})
export class Sort24hPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction)
      return value.sort((a, b) => {
        const keyA = Object.keys(a).filter(v => v !== 'name')[0];
        const keyB = Object.keys(b).filter(v => v !== 'name')[0];
        if (direction === 'up')
          return a[keyA].h24 - b[keyB].h24;
        else
          return b[keyB].h24 - a[keyA].h24;
      });
    return value;
  }
}
