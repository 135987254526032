import { Injectable } from '@angular/core';
import {computed, observable} from 'mobx';
import {RestrictedCountriesClass} from '../models/restricted-countries.class';
import {DataService} from '../services/data.service';
import {CountryClass} from '../models/country.class';
import {IS_GEO_TURNED_ON, RESTRICTED_CITIES, RESTRICTED_COUNTRIES} from '../app.constants';

@Injectable({
  providedIn: 'root'
})
// Test
export class GeoState {
  isGEOturnedON = IS_GEO_TURNED_ON;
  restrictedCounries = RESTRICTED_COUNTRIES;
  restrictedCities = RESTRICTED_CITIES;

  @observable userCountry: RestrictedCountriesClass;
  @observable userIsoCountry: string;
  @observable userCity: string;
  @observable residenceCountry: number;
  @observable citizenshipCountry: number;
  @observable countries: Array<CountryClass> = [];
  @observable blackListCountriesIsoNames: Array<string> = [];

  @computed get blackListCountriesIndexes(): Array<number> {
    return this.blackListCountriesIsoNames?.map(
      (isoName) => this.countries?.find((country) => country.code === isoName)?.id
    )?.filter((id) => !!id);
  }

  @computed get isResidenceCountryInBlackList(): boolean {
    return this.blackListCountriesIndexes?.some((country) => this.residenceCountry === country);
  }

  @computed get isGeoCountryIsInBlackList(): boolean {
    return this.blackListCountriesIsoNames?.some((country) => this.userIsoCountry === country);
  }

  @computed get isGeoCountryIsInRestricktedList(): boolean {
    return this.restrictedCounries?.some((country) => this.userIsoCountry === country.ISO);
  }

  @computed get isCitizenshipInBlackList(): boolean {
    return this.blackListCountriesIndexes?.some((country) => this.citizenshipCountry === country);
  }

  @computed get isGeoCityIsInBlackList(): boolean {
    return this.restrictedCities?.some((city) => this.userCity === city);
  }

  constructor(private _dataService: DataService) {}

  loadBlackListCountries() {
    this._dataService.getServerBlackListCountries().toPromise().then(
      (data) => this.blackListCountriesIsoNames = data.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
      })
    );
  }

  loadBlackListCountriesIfEmpty() {
    if (!this.blackListCountriesIsoNames?.length) {
      this.loadBlackListCountries();
    }
  }

  findUserCountry() {
    if (this.isGEOturnedON && !this.userIsoCountry) {
      this._dataService.getDataByIP('')
        .subscribe(result => {
          // console.log(result);
          const userIsoCountry = result.country ? result.country : '';
          const userCity = result.city ? result.city : '';
          const tempCountries = this.restrictedCounries.filter(country => country.ISO === userIsoCountry);
          // console.log(tempCountries);
          this.userCountry = (tempCountries && tempCountries.length === 1) ? tempCountries[0] : undefined;
          this.userIsoCountry = userIsoCountry;
          this.userCity = userCity;

        }, error1 => {
          console.log(error1);
        });
    }
  }

  loadCountries() {
    this._dataService.getServerCountries().toPromise().then(
      (data) => {
        this.countries = data;
      }
    );
  }

  loadCountriesIfEmpty() {
    if (!this.countries?.length) {
      this.loadCountries();
    }
  }

  setResidenceCountry(country: number) {
    this.residenceCountry = country;
  }

  setCitizenshipCountry(country: number) {
    this.citizenshipCountry = country;
  }

  setUserIsoCountry(country: string) {
    this.userIsoCountry = country;
  }

}
