import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'marketsSort'
})
export class MarketsSortPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args === 'up')
      return value.sort((a, b) => a.BaseVolume - b.BaseVolume);
    else if (args === 'down')
      return value.sort((a, b) => b.BaseVolume - a.BaseVolume);
    else return value;
  }

}
