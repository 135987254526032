import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ThemesModel } from '../../../models/themes.model';
import { SvgService } from '../../../services/svg.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-order-markets',
  templateUrl: './order-markets.component.html',
  styleUrls: ['./order-markets.component.scss']
})
export class OrderMarketsComponent implements OnDestroy {
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public isLightTheme = false;
  public ThemesModel = ThemesModel;
  public toggleMenu: boolean;
  destroySubject$: Subject<void> = new Subject();

  constructor(
    public dataService: DataService,
    public svgService: SvgService,
  ) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

}
