import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {InfoComponent} from '../info/info.component';
import {EMAIL_VALIDATION, PASSWORD_VALIDATION} from '../../app.constants';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {GeoState} from '../../store/geo.state';
import {fromMobx} from '../../store/rx-from-mobx';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnDestroy {
  public signUpForm: FormGroup;
  public displayPassword = false;
  public displayRepeatPassword = false;
  public wrongEmailPrev = 'wrongEmailPrev@mail.com';
  private customRedirect: boolean;
  // public reCaptcha_version = RECAPTCHA_VERSION;
  public reCaptcha_version = environment.name || 'prod';
  public termsCookieAndPrivacyPolicy = environment.name || 'prod';
  public errors: any;
  public pendingRequest: boolean;
  public gdprCaption = 'GDPR';
  public isLightTheme = false;
  public userCity = '';
  public isGeoBlockedCity = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(public dialogRef: MatDialogRef<SignUpComponent>,
              public dataService: DataService,
              public geoState: GeoState,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private authService: AuthService,
              private router: Router) {
    this.dialogRef.afterClosed()
      .subscribe(() => {
        if (!this.customRedirect) {
          this.router.navigate(['', {outlets: { popup: null }}]).then();
        }
      });
    this.errors = {
      userExist: false,
      passwordMatch: false,
      passwordInvalid: false
    };
    this.createForm();
    if (this.termsCookieAndPrivacyPolicy === 'audev' || this.termsCookieAndPrivacyPolicy === 'auprod') {
      this.signUpForm.controls['agreeWithCookiePolicy'].setValidators(Validators.required);
      this.signUpForm.controls['agreeWithPrivacyPolicy'].setValidators(Validators.required);
    }
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
    if (this.reCaptcha_version === 'auprod') {
      this.gdprCaption = 'Corporation Act';
    }
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public checkEmailIsValid() {
    this.errors.passwordInvalid = this.email.invalid;
    // console.log(this.email);
  }

  public checkEmailIsValidDuringInput() {
    if (this.errors.passwordInvalid || this.email.dirty) {
      this.errors.passwordInvalid = this.email.invalid;
    }
  }

  public signUp(): void {
    if (this.signUpForm.valid) {
      this.pendingRequest = true;
      this.checkIfCityIsRestricted();
      const signUpData = {
        email: this.dataService.getEmailLowercase(this.signUpForm.value.email),
        password: this.signUpForm.value.password,
        is_GDPR: this.gdrp.value ? 1 : 0
      };
      //   myRecaptcha: this.signUpForm.value.myRecaptcha,
      // };
      this.authService.register(signUpData)
        .subscribe(() => {
            this.pendingRequest = false;
            this.customRedirect = true;
            this.dialogRef.close();
            this.dialog.open(InfoComponent, {data: {reason: 'registration-success'}});
            // this.router.navigate(['/login'])*,
            this.wrongEmailPrev = this.dataService.getEmailLowercase(this.signUpForm.value.email);
          },
          e => {
            this.pendingRequest = false;
            switch (e.error.errorCode) {
              case '417_13': {
                this.errors.userExist = true;
              }
            }
            this.wrongEmailPrev = this.dataService.getEmailLowercase(this.signUpForm.value.email);
          });
    }
  }

  private checkIfCityIsRestricted() {
    let userCity = this.geoState.userCity;
    this.userCity = userCity;

    if (userCity && userCity.length) {
      const isGeoCityIsInBlackList = this.geoState.isGeoCityIsInBlackList;
      if (isGeoCityIsInBlackList) {
        this.pendingRequest = false;
        this.isGeoBlockedCity = true;
        this.authService.logout();
        return;
      }
    } else {
      fromMobx(() => this.geoState.userCity)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response => {
          userCity = response;
          if (userCity && userCity.length) {
            const isGeoCityIsInBlackList = this.geoState.isGeoCityIsInBlackList;
            if (isGeoCityIsInBlackList) {
              this.pendingRequest = false;
              this.isGeoBlockedCity = true;
              this.authService.logout();
              return;
            }
          }
        });
      }
  }

  public redirectClose() {
    this.customRedirect = true;
    this.dialogRef.close();
  }

  private createForm(): void {
    const isProdEnv = (this.reCaptcha_version === 'prod') || (this.reCaptcha_version === 'auprod');
    this.signUpForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_VALIDATION)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern(PASSWORD_VALIDATION)]],
      passwordRepeat: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20),
        Validators.pattern(PASSWORD_VALIDATION)]],
      agreeWithTerms: [false, Validators.required],
      agreeWithLaw: [false, Validators.required],
      agreeWithCookiePolicy: [false],
      agreeWithPrivacyPolicy: [false],
      agreeWithGdrp: [false, Validators.required],
      myRecaptcha: ['', Validators.required]
    }, {
      validator: this.passwordMatch
    });
  }

  private passwordMatch(group: FormGroup): void {
    const pw = group.controls['password'];
    const pwr = group.controls['passwordRepeat'];
    if (pw.value !== pwr.value) {
      pwr.setErrors({passwordMatch: true});
    } else {
      pwr.setErrors(null);
    }
  }

  get email() {
    return this.signUpForm.get('email');
  }

  get password() {
    return this.signUpForm.get('password');
  }

  get passwordRepeat() {
    return this.signUpForm.get('passwordRepeat');
  }

  get gdrp() {
    return this.signUpForm.get('agreeWithGdrp');
  }

  public resolved(captchaResponse: string): void {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    // this.verifyRecapcha({'reCaptchaToken': captchaResponse});
  }

  // public verifyRecapcha(recaphaToken) {
  //   this.userService.verifyRecapcha(recaphaToken).subscribe((response) => {
  //     // console.log('verify recapha', response);
  //   }, (error) => {
  //     console.log('verify recapcha error', error);
  //   });
  // }


}
