import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'change24hSort'
})
export class Change24hSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction) {
      return value.sort((a, b) => {
        if (direction === 'up') {
          return ((b.Last - b.PrevDay) / b.PrevDay) - ((a.Last - a.PrevDay) / a.PrevDay);
        } else {
          return ((a.Last - a.PrevDay) / a.PrevDay) - ((b.Last - b.PrevDay) / b.PrevDay);
        }
      });
    }
    return value;
  }

}
