export interface TokensInterface {
  accessToken: string;
  accessTokenExpiredAt: number;
  refreshToken: string;
}

export class TokensModel implements TokensInterface {
  accessToken = '';
  accessTokenExpiredAt = undefined;
  refreshToken = '';

  constructor(data?: TokensInterface) {
    Object.assign(this, data);
  }
}
