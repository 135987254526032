import {Component, OnDestroy, Renderer2} from '@angular/core';
import { DataService } from '../../../services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { TwoFactorAuthModalComponent } from '../../../dialogs/two-factor-auth/two-factor-auth-modal.component';
import {AuthService} from '../../../services/auth.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-ip-whitelist',
  templateUrl: './ip-whitelist.component.html',
  styleUrls: ['./ip-whitelist.component.scss']
})
export class IpWhitelistComponent implements OnDestroy {
  public isLightTheme = false;
  public ipParts: Array<string>;
  public enableIpWhiteList: boolean;
  public ipWhiteList;
  public errors = {
    tFaInvalid: false
  };
  destroySubject$: Subject<void> = new Subject();

  constructor(private dataService: DataService,
              private dialog: MatDialog,
              public authService: AuthService,
              private renderer: Renderer2) {
    this.ipParts = ['', '', '', ''];
    if (this.authService.isLoggedIn) {
      this.dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          if (res['2fa_enabled']) {
            this.enableIpWhiteList = true;
          }
        });
      this.getWhiteList();
    }
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }
  public invalidIp(arr): boolean {
    return arr.reduce((sum, current) => {
      if (current.length > 0) {
        return sum + 1;
      }
    }, 0) !== 4;
  }
  public keyHandle(id, key): void {
    if (key.keyCode !== 39 && key.keyCode !== 37) {
      if (key.keyCode === 8) {
        if (this.ipParts[id].length === 0 && id !== 0) {
          this.focus(`#ipPart${id - 1}`);
        }
      } else if (key.keyCode === 32 && id !== 3) {
        if (this.ipParts[id].length !== 0) {
          this.focus(`#ipPart${id + 1}`);
        }
        key.preventDefault();
      } else if (!(Number.isInteger(+key.key))) {
        key.preventDefault();
      } else if (this.ipParts[id].length === 3 && id !== 3) {
          this.focus(`#ipPart${id + 1}`);
      }
    }
  }
  private getWhiteList(): void {
    this.dataService.getIpWhiteList()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        this.ipWhiteList = res;
      });
  }
  public deleteFromList(ip): void {
    this.clearErrors();
    this.dialog.open(TwoFactorAuthModalComponent)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          const deleteData = {
            ip,
            code: res.code
          };
          this.dataService.removeFromIpWhiteList(deleteData)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              this.getWhiteList();
            }, err => this.handleError(err));
        }
      }, err => this.handleError(err));
  }
  public addToList(): void {
    this.clearErrors();
    const ip = this.ipParts.join('.');
    this.dialog.open(TwoFactorAuthModalComponent)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          const addData = {
            ip,
            code: res.code
          };
          this.dataService.addToIpWhiteList(addData)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              this.getWhiteList();
              this.clearIpParts();
              this.focus('#ipPart0');
            }, err => this.handleError(err));
        }
      }, err => this.handleError(err));
  }
  private focus(id): void {
    this.renderer.selectRootElement(id)
      .focus();
  }
  private clearErrors() {
    Object.keys(this.errors).forEach(v => this.errors[v] = false);
  }
  private handleError(err) {
    if (err.error.errorCode === '401_7') {
      this.errors.tFaInvalid = true;
    }
  }
  private clearIpParts(): void {
    this.ipParts = ['', '', '', ''];
  }

}
