import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showCurrency'
})
export class ShowCurrencyPipe implements PipeTransform {

  transform(amount: any, currency?: string): any {
    if (amount) {
      if (currency) {
        return amount + ' ' + currency;
      } else {
        return amount;
      }
    } else { return ''; }
  }

}
