import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'walletHideZero'
})
export class WalletHideZeroPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args) {
      return value.filter(item => {
        return (item.balance > 0 || item.pending > 0);
      });
    } else {
       return value;
    }
  }

}
