import {Directive, forwardRef, Injector, Input, OnDestroy, OnInit, StaticProvider} from '@angular/core';
import {DataService} from '../services/data.service';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, NgControl, ValidationErrors} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {debounceTime, distinctUntilChanged, mergeMap, takeUntil} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Subject} from 'rxjs/internal/Subject';

export const BIX_CHECK_BALANCE: StaticProvider = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => CheckBalanceDirective),
  multi: true
};

@Directive({
  selector: '[appCheckBalance]',
  providers: [BIX_CHECK_BALANCE]
})
export class CheckBalanceDirective implements AsyncValidator, OnInit, OnDestroy {

  @Input() appCheckBalance: string;

  private _onChange!: () => void;

  private _destroyer = new Subject<void>();

  constructor(private _dataService: DataService,
              private _injector: Injector) { }

  ngOnInit(): void {
    const ngControl = this._injector.get(NgControl);

    this._dataService
      .SubscribeToUserBalances()
      .pipe(takeUntil(this._destroyer))
      .subscribe(() => {
        if (ngControl) {
          ngControl.control.updateValueAndValidity();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyer.next();
    this._destroyer.complete();
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  // balance: 0
  // currency: "XRP"
  // currencyBase: "Ripple"
  // id: "#"
  // pending: 0
  // public_key: ""
  // reserved: 0
  // status: "Normal"
  // tag: ""
  // valueUSDT: 0

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!this.appCheckBalance) {
      return of(null);
    }

    return this._dataService
      .getWallets()
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        mergeMap(wallets => {
        const currencyWallet = wallets.find(w => w.currency.toUpperCase() === this.appCheckBalance.toUpperCase());
        if (!currencyWallet) {
          return of({ wallet: 'notFound' });
        }

        const value = +control.value;
        if (value > currencyWallet.balance) {
          return of({ walletBalance: currencyWallet.balance });
        }

        return of(null);
      }));
  }

}
