import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'volumeUSDSort'
})
export class VolumeUSDSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction) {
      return value.sort((a, b) => {
        if (direction === 'up') {
          return b.BaseVolumeUSDT - a.BaseVolumeUSDT;
        } else {
          return a.BaseVolumeUSDT - b.BaseVolumeUSDT;
        }
      });
    }
    return value;
  }

}
