import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SvgService } from '../../services/svg.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import {DataService} from '../../services/data.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public forgotPasswordForm: FormGroup;
  public wrongEmailPrev = 'wrongEmailPrev@mail.com';
  public mailSent: boolean;
  public isError: boolean;
  public errorMessage: string ;
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(private matDialogRef: MatDialogRef<ForgotPasswordComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private authService: AuthService,
              private fb: FormBuilder,
              private router: Router,
              public svgService: SvgService) {
    this.createForm();
    this.isError = false;
    this.mailSent = false;
    this.errorMessage =  'Added mail not valid';
    this.matDialogRef.afterClosed()
      .subscribe(() => {
        this.router.navigate(['', {outlets: { popup: null }}]).then();
      });

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  public sendEmail(): void {
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.dataService.getEmailLowercase(this.forgotPasswordForm.value.email))
        .subscribe(
          () => {
            this.mailSent = true;
            this.isError = false;
            this.wrongEmailPrev = this.dataService.getEmailLowercase(this.forgotPasswordForm.value.email);
          },
          () => {
            this.isError = true;
            this.wrongEmailPrev = this.dataService.getEmailLowercase(this.forgotPasswordForm.value.email);
          });
    }
  }
  private createForm(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
  public close() {
    this.matDialogRef.close();
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

}
