export class RestrictedCountriesClass {
  name: string;
  ISO: string;

  constructor(name: string,
              ISO: string
  ) {
    this.name = name;
    this.ISO = ISO;
  }

}
