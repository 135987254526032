export class CurrencyTagClass {
  currency: string;
  text: string;

  constructor(currency: string,
              text: string
  ) {
    this.currency = currency;
    this.text = text;
  }

}
