import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySort'
})
export class CurrencySortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction) {
      return value.sort((a, b) => {
        if (direction === 'up') {
          if (a.MarketFullName < b.MarketFullName) {
            return 1;
          }
          if (a.MarketFullName > b.MarketFullName) {
            return -1;
          }
          return 0;
        } else if (direction === 'down') {
          if (a.MarketFullName < b.MarketFullName) {
            return -1;
          }
          if (a.MarketFullName > b.MarketFullName) {
            return 1;
          }
          return 0;
        }
      });
    }
    return value;
  }
}
