import {Component, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { TwoFactorAuthModalComponent } from '../../../dialogs/two-factor-auth/two-factor-auth-modal.component';
import { AuthService } from '../../../services/auth.service';
import {InfoComponent} from '../../../dialogs/info/info.component';
import {PASSWORD_VALIDATION} from '../../../app.constants';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnDestroy {
  public isLightTheme = false;
  private enabled2Fa: boolean;
  public passwordForm: FormGroup;
  public email;
  public changeSuccess = false;
  public errors = {
    wrongPassword: false,
    other: false
  };
  destroySubject$: Subject<void> = new Subject();
  constructor(private fb: FormBuilder,
              public authService: AuthService,
              public dataService: DataService,
              private dialog: MatDialog,
              private router: Router) {
    this.createForm();
    if (this.authService.isLoggedIn) {
      this.dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          this.email = res.email;
          if (res['2fa_enabled']) {
            this.enabled2Fa = true;
          }
        });
    }
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  private createForm(): void {
    this.passwordForm = this.fb.group({
      oldPass: ['', Validators.required],
      newPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern(PASSWORD_VALIDATION)]],
      newPassRepeat: ['', Validators.required]
    }, {
      validator: this.passwordMatch
    });
  }
  private passwordMatch(group: FormGroup): void {
    const pw = group.controls['newPass'];
    const pwr = group.controls['newPassRepeat'];
    if (pw.value !== pwr.value) {
      pwr.setErrors({ValidatePasswordConfirmation: true});
    } else {
      pwr.setErrors(null);
    }
  }

  public changePassword(): void {
    this.clearErrors();
    if (this.passwordForm.valid) {
      const data = <any>{
        oldPassword: this.passwordForm.value.oldPass,
        newPassword: this.passwordForm.value.newPass
      };
      if (this.enabled2Fa) {
        this.dialog.open(TwoFactorAuthModalComponent)
          .afterClosed()
          .subscribe(res => {
            if (res) {
              data.code = res.code;
              this.changePasswordRequest(data);
            }
          });
      } else {
        this.changePasswordRequest(data);
      }
    }
  }
  private changePasswordRequest(data): void {
    this.authService.changePassword(data)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        if (this.enabled2Fa) {
          this.changeSuccess = true;
        } else {
          this.dialog.open(InfoComponent, {data: {
              reason: 'password-change-verify', message: ''
            }}).afterClosed()
            .subscribe(() => { this.router.navigate(['/user-settings/password-settings']).then(); });
        }
      }, error => {
        switch (error.error.errorCode) {
          case '403_1':
            this.errors.wrongPassword = true;
            break;
          default:
            this.errors.other = true;
            break;
        }
      });
    this.passwordForm.reset();
  }
  private clearErrors() {
    Object.keys(this.errors).forEach(v => this.errors[v] = false);
  }
  get newPass() { return this.passwordForm.get('newPass'); }
  get newPassRepeat() { return this.passwordForm.get('newPassRepeat'); }

}
