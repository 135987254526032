import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {SvgService} from '../../../services/svg.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {ThemesModel} from '../../../models/themes.model';
import Datafeed from './datafeed';
import {ActivatedRoute} from '@angular/router';
declare const TradingView: any;

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
  public isLightTheme = false;
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public chartProperties_ls: any;
  public interval: string;
  public ls_interval: string;
  public symbol = '';
  public pair = '';
  public theme = 'Light';
  public ThemesModel = ThemesModel;
  private _noControls: boolean = !!this._route.snapshot.data.noControls;

  destroySubject$: Subject<void> = new Subject();

  constructor(public dataService: DataService,
              public svgService: SvgService,
              private _route: ActivatedRoute,
  ) {
    this.initialDataLoadingFirst();
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        // console.log(this.dataService.getPair());
        const pair = this.dataService.getPair().split('-').reverse().join('');
        // console.log(pair);
        this.pair = pair;
        this.reloadTraidingViewWithNewPair(pair, this.theme);
      });
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.theme = this.isLightTheme ? 'Light' : 'Dark';
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
        const theme = this.isLightTheme ? 'Light' : 'Dark';
        this.theme = theme;
        const pair = this.dataService.getPair().split('-').reverse().join('');
        this.reloadTraidingViewWithNewPair(pair, theme);
      });
  }

  ngOnInit() {
    this.initialDataLoadingSecond();
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        // this.isVisibleActualPair = true;
        try {
          this.initialDataLoadingFirst();
          this.initialDataLoadingSecond();
          // setTimeout(() => this.isVisibleActualPair = false, 2000);
        } catch (e) {
          // console.log(e);
        }
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  getTvProps = (pair) => ({
    'autosize': true,
    'symbol': 'BITTREX:' + pair,
    'interval': 'D',
    'timezone': 'exchange',
    'theme': this.theme,
    'style': '1',
    'locale': 'en',
    'toolbar_bg': '#f1f3f6',
    'container_id': 'tradingview_6c3f6',
    'enable_publishing': false,
    'hide_side_toolbar': this._noControls,
    'overrides': {
      // "mainSeriesProperties.showCountdown": true,
      'paneProperties.background': '#131722',
    }
  })

  ngAfterViewInit() {
    setTimeout(() => {
      const pair = this.dataService.getPair().split('-').reverse().join('');
      new TradingView.widget(this.getTvProps(pair));
    }, 400);
  }

  public reloadTraidingViewWithNewPair(pair, theme) {
    const element = document. getElementById('tradingview_6c3f6');
    const parentEl = element.parentNode;

    const siblingEl = document.getElementsByClassName('tradingview-widget-copyright')[0];
    const divWidget = document.createElement('div');
    divWidget.id = 'tradingview_6c3f6';
    parentEl.insertBefore(divWidget, siblingEl);

    new TradingView.widget(this.getTvProps(pair));
  }

  public initialDataLoadingFirst() {
    this.chartProperties_ls = JSON.parse(localStorage.getItem('tradingview.chartproperties'));
    // console.log(this.chartProperties_ls);
    this.ls_interval = (this.chartProperties_ls && this.chartProperties_ls.mainSeriesProperties
      && this.chartProperties_ls.mainSeriesProperties.interval) ? this.chartProperties_ls.mainSeriesProperties.interval : '';
    // this.ls_interval = this.chartProperties_ls.mainSeriesProperties.interval;
    // console.log(this.ls_interval);
    this.pair = this.dataService.getPair().split('-').join('/');
  }

  public initialDataLoadingSecond() {
    this.symbol = this.dataService.market;
    this.interval = this.ls_interval || '60';
  }
}
