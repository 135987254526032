import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SvgService } from '../../services/svg.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-withdrawal-success',
  templateUrl: './withdrawal-success.component.html',
  styleUrls: ['./withdrawal-success.component.scss']
})
export class WithdrawalSuccessComponent {
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();
  constructor(private matDialogRef: MatDialogRef<WithdrawalSuccessComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public svgService: SvgService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  public close(): void {
    this.matDialogRef.close();
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }
}
