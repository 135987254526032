import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SvgService} from '../../services/svg.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {TwoFactorAuthModalComponent} from '../two-factor-auth/two-factor-auth-modal.component';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {WalletFeeCurrencyInterface} from '../../models/wallet-fee-currency.model';
import {CURRENCIES_WITH_TAG} from '../../app.constants';
import {CurrencyTagClass} from '../../models/currency-tag.class';
import {AuthService} from '../../services/auth.service';
import {MyProfileInterface} from '../../models/my-profile.model';
import {WalletBalanceInterface} from '../../models/wallet-balance.model';
import {ProfileState} from '../../store/profile.state';
import {fromMobx} from '../../store/rx-from-mobx';

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss']
})
export class WithdrawalComponent implements OnDestroy {
  private success: boolean;
  public currencyName: string;
  public network: string;
  public withdrawForm: FormGroup;
  public biteeuFee: number;
  public vendorFee: number;
  public walletBalance: WalletBalanceInterface;
  public address: string;
  public amountValue: number;
  public receivedAmount = 0;
  public precision = 10000000000;
  public currenciesWithTag = CURRENCIES_WITH_TAG;
  public currencyWithTag: CurrencyTagClass;
  public isCurrencyWithTag: boolean;
  public isPending = false;
  public isUserCompletedProfileForm = false;
  public isChecked2Fa = false;
  public isEnabled2Fa: boolean;
  public withdrawsAvailableLimit = undefined;
  public blockedMarkets = [];
  public errors = {
    tFaInvalid: false,
    unfilledProfile: false,
    notInWhiteList: false,
    notEnoughBalance: false,
    activeWithdraw: false,
    other: ''
  };
  public isTechnicalError = false;
  destroySubject$: Subject<void> = new Subject();
  public isLightTheme = false;

  public faControl = new FormControl('');


  constructor(public dialogRef: MatDialogRef<WithdrawalComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              public dataService: DataService,
              public profileState: ProfileState,
              public authService: AuthService,
              public svgService: SvgService) {
    this.loadIsUserCompletedProfileFormData();
    this.loadWithdrawsAvailableLimit();
    const userId = +localStorage.getItem('visible-set');

    console.log('WITHDRAWAL PAGE');

    if (data.currency && data.currency === 'USDT') {
      // console.log('userId', userId);
      if ((userId === 344) || (userId === 135) || (userId === 309) || (userId === 229) || (userId === 343) || (userId === 50)) {
        // this.isTechnicalError = false;
      } else {
        // this.isTechnicalError = true;
      }
      // console.log('this.isTechnicalError', this.isTechnicalError);
    }
    if (data.blockedMarkets) {
      this.blockedMarkets = data.blockedMarkets;
    }
    this.walletBalance = this.data.walletBalance;
    this.address = this.data.address;
    this.isCurrencyWithTag = this.currenciesWithTag.some((currency) => {
      return currency.currency === this.data.currency;
    });
    if (this.isCurrencyWithTag) {
      this.currencyWithTag = Object.assign({}, this.currenciesWithTag.filter((currency) => {
        return currency.currency === data.currency;
      })[0]);
    }
    this.createForm();
    this.dataService.getWalletFeeCurrency(this.data.currency)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(fee => {
        // console.log('this.data', this.data);
        // console.log('fee', fee);
        if (fee && fee.res) {
          this.biteeuFee = +(<WalletFeeCurrencyInterface>fee.res).biteeuFee ? +(<WalletFeeCurrencyInterface>fee.res).biteeuFee : 0;
          this.vendorFee = +(<WalletFeeCurrencyInterface>fee.res).vendorFee ? +(<WalletFeeCurrencyInterface>fee.res).vendorFee : 0;
        }
      });

    this.dataService.getUserProfile()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: MyProfileInterface) => {
        this.check2FaIsEnabled(res.email);
      });

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public check2FaIsEnabled(email: string): void {
    this.authService.check2FaIsEnabled(email)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(response => {
          // console.log('check2FaIsEnabled', response);
          this.isChecked2Fa = true;
          this.isEnabled2Fa = response.success;
          // console.log('this.isEnabled2Fa', this.isEnabled2Fa);
        },
        () => {
          this.isChecked2Fa = true;
        });
  }

  public countReceivedAmount() {
    // this.receivedAmount = (this.amount.value ? this.amount.value - this.biteeuFee * this.amount.value : 0) - this.vendorFee;
    this.receivedAmount = this.amount.value ?
      ((this.amount.value * this.precision) - (this.vendorFee * this.precision) - (this.biteeuFee * this.precision)) / this.precision : 0;
  }

  private createForm(): void {
    this.withdrawForm = this.fb.group({
      address: ['', Validators.required],
      amount: ['', Validators.required],
      tag: ['', [Validators.required, Validators.minLength(1)]],
      agreeWithTerms: false
    });
    if (!this.isCurrencyWithTag) {
      this.withdrawForm.controls['tag'].setValue('not empty data');
    } else {
      this.withdrawForm.controls['tag'].setValue('');
    }
  }

  public withdraw(): void {
    this.makeWithdraw(this.faControl.value);
  }

  public makeWithdraw(code: string) {
    this.isPending = true;
    let withdrawData;
    withdrawData = {
      code: code,
      public_address: this.data.address,
      currency: this.data.currency,
      value: this.data.amount,
      tag: this.data.tag,
    };
    // console.log(withdrawData);
/*
    this.handleSuccess({success: true});
    this.isPending = false;
*/
    this.dataService.withdrawal(withdrawData)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(response => {
        console.log(response);
        this.handleSuccess(response);
        this.isPending = false;
      }, err => {
        this.handleError(err);
        this.isPending = false;
      });
  }

  public close() {
    this.dialogRef.close({success: this.success});
  }

  private clearErrors() {
    Object.keys(this.errors).forEach(v => this.errors[v] = false);
  }

  private handleSuccess(res) {
    this.errors.other = '';
    if (res.success) {
      this.success = true;
      this.close();
    } else {
      this.errors.other = res.message;
    }
  }

  private handleError(err) {
    this.errors.other = 'Unknown error';
    switch (err?.error?.errorCode) {
      case '401_7': {
        this.errors.tFaInvalid = true;
        break;
      }
      case '417_43': {
        this.errors.unfilledProfile = true;
        break;
      }
      case '417_54': {
        this.errors.notInWhiteList = true;
        break;
      }
      case '417_56': {
        this.errors.notEnoughBalance = true;
        break;
      }
      case '400_30': {
        this.errors.activeWithdraw = true;
        break;
      }
      default:
        this.errors.other = err.error.message || 'Unknown error';
        break;
    }
    if (err.error == 'Unauthorized') {
      this.errors.other = 'Invalid 2fa code';
    }
  }

  get amount() {
    return this.withdrawForm.get('amount');
  }

  public loadIsUserCompletedProfileFormData() {
    this.isUserCompletedProfileForm = true;
    /*
        this.isUserCompletedProfileForm = this.dataService.getIsUserCompletedProfileForm();
        if (!this.isUserCompletedProfileForm) {
          this.dataService.passIsVisibleProfileEmptyFields(true);
          this.dataService.setIsVisibleProfileEmptyFields(true);
        }
        // console.log('isUserCompletedProfileForm 1', this.isUserCompletedProfileForm);
        this.dataService.getIsUserCompletedProfileFormEmitter()
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(data => {
            this.isUserCompletedProfileForm = data;
            if (!this.isUserCompletedProfileForm) {
              this.dataService.passIsVisibleProfileEmptyFields(true);
              this.dataService.setIsVisibleProfileEmptyFields(true);
            }
            // console.log('isUserCompletedProfileForm 4', this.isUserCompletedProfileForm);
          });
    */
  }

  public loadWithdrawsAvailableLimit() {
    fromMobx(() => this.profileState.withdrawsAvailableLimit)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.withdrawsAvailableLimit = data;
      });
  }

  public isCurrencyBlocked(currency: string): boolean {
    return this.blockedMarkets.includes(currency);
  }
}
