export const tu = {
    header: {
        navigation: [
            {
                id: 1,
                url: 'https://iampartof.org/',
                name: 'IAMPARTOF - HAYIR',
            },
            {
                id: 2,
                url: '/tutorial',
                name: 'KILAVUZ',
            },
            {
                id: 3,
                url: 'http://trade.biteeu.com/login/tr',
                name: 'GİRİŞ',
            },
            {
                id: 4,
                url: 'http://trade.biteeu.com/sign-up/tr',
                name: 'KAYDOL',
            },
        ],
    },
    mobileView: 'Mobil uygulama',
    home: {
        promotionSection: {
            banner2: {
                title: 'Birleşik Krallık\'ta düzenlenmiş hayır kurumu ile ortaklığımızı duyurmaktan mutluluk duyuyoruz',
                buttonText: 'ŞUNA GİT',
            },
        },
        chartSection: {
            title: 'BITEEU – küresel olan kullanılabilir hale gelmektedir.',
            description: 'Avrupa Birliği’nden tüm yasal gereklilikleri karşılayan lisanslı sanal para birimleri borsası.',
            register: 'KAYDOL',
            explore: 'PİYASALARI ÖĞREN',
        },
        partnersSection: 'Ortaklar',
        featuresSection: {
            title: 'Ana özellikleri',
            features: [
                {
                    id: 1,
                    src: 'images/features/safety.svg',
                    title: 'Güvenlik',
                    subtitle: 'En büyük önceliğimiz, fonlarınızın ve kişisel verilerinizin güvenliğidir.',
                },
                {
                    id: 2,
                    src: 'images/features/platform.svg',
                    title: 'Çeşitli platformlar ile uyumludur',
                    subtitle: 'Web, iOS, Android, Windows, MacOS.',
                },
                {
                    id: 3,
                    src: 'images/features/comission.svg',
                    title: 'Rekabetçi komisyonlar',
                    subtitle: 'Hem para yatırmak hem de para çekmek için rekabetçi komisyonlar. Tarife yapısı şeffaftır ve hiçbir gizli komisyon ücretleri yoktur.',
                },
                {
                    id: 4,
                    src: 'images/features/multilingual.svg',
                    title: '7/24 çoklu dil desteği',
                    subtitle: 'Destek servisimiz yasal ve teknik bilgiye sahiptir ve İngilizce, Rusça ve Çince dahil olmak üzere farklı dillerde size yardımcı olmaya hazırdır.',
                },
                {
                    id: 5,
                    src: 'images/features/chart-f.svg',
                    title: 'Profesyonel ticari ve teknik araçlar',
                    subtitle: 'Tamamen özelleştirilebilir ve esnek zamanlama Gelişmiş ticaret araçlarıyla TradingView.',
                },
                {
                    id: 6,
                    src: 'images/features/legal.svg',
                    title: 'Yasal ve tüm yasal gerekliliklere uyumlu',
                    subtitle: 'Borsamız lisanslıdır ve yerel yasalara ve Düzenleyicinin gereklerine uygundur.',
                },
            ],
        },
        tradingAppSection: {
            title: 'Mobil uygulama',
        },
    },
    tutorial: {
        title: 'BITEEU eğitim videoları:',
    },
    about: {
        title: 'Hakkımızda',
        texts: [
            'BITEEU DCX OU, bankacılık sektöründe, borsada ve uluslararası şirketlerde geniş deneyime sahip profesyonellerin katılımıyla 2019 yılında Estonya Cumhuriyeti\'nde kuruldu. Avrupa Birliği’nde lisanslı ve düzenlenen bir sanal döviz borsasıdır. Biz dürüstlüğe, güvenliğe ve güvenilirliğe yüksek değer veriyoruz, bu nedenle yerel mevzuatın ve uluslararası belgelerin katı gereklerine uygun olarak Kara Para Aklama ile Mücadele Politikamızı ve “Müşterini Tanı” Politikamızı geliştirdik.',
            'BITEEU DCX OU, güvenlik, güvenilirlik ve rahatlık alanındaki en gelişmiş teknolojileri entegre etmek ve uygulamak için Bittrex ile işbirliği yapmaktadır. Platformumuz İngilizce, Rusça ve Çince dahil olmak üzere birçok dili desteklemektedir. Platform, 24 saat müşteri hizmeti ve varlıklarınızın ve kişisel verilerinizin güvenliğini sağlamaktadır.',
            'Şu anda, BITEEU DCX OU cüzdan sistemi 50\'den fazla sanal para birimini destekliyor ve Bittrex ile ortaklık sayesinde gelecekte Platform\'a yeni sanal para birimlerini düzenli olarak ekleyecektir.',
        ],
    },
    footer: {
        socials: [
            {
                id: 1,
                href: '/twitter',
                label: 'Follow us on Twitter',
                src: 'assets/img/socials/tt.svg',
            },
            {
                id: 2,
                href: '/instagram',
                label: 'Follow us on Instagram',
                src: 'assets/img/socials/insta.svg',
            },
            {
                id: 3,
                href: '/linkedin',
                label: 'Follow us on Linkedin',
                src: 'assets/img/socials/linked-icon.svg',
            },
            {
                id: 4,
                href: '/telegram',
                label: 'Follow us on Telegram',
                src: 'assets/img/socials/telegram.svg',
            },
        ],
        usefulLinks: [
            {
                id: 1,
                href: '/docs/ippolicy',
                label: 'Çerez politikası',
            },
            {
                id: 2,
                href: '/docs/privacyestonia',
                label: 'Gizlilik politikası',
            },
            {
                id: 3,
                href: '/docs/termsofuseestonia',
                label: 'Kullanım şartları',
            },
            {
                id: 4,
                href: '/about',
                label: 'Hakkımızda',
            },
            {
                id: 5,
                href: '/fees-and-limits',
                label: 'Komisyonlar ve limitler',
            },
            {
                id: 6,
                href: '/faq',
                label: 'FAQ',
            },
            {
                id: 7,
                href: '/wallet-status',
                label: 'Cüzdan durumu',
            },
        ],
        asSeen: 'Görüldüğü gibi:',
    },
};
