import { Pipe, PipeTransform } from '@angular/core';
import {WalletBalanceInterface} from '../../models/wallet-balance.model';

@Pipe({
  name: 'walletSearch'
})
export class WalletSearchPipe implements PipeTransform {

  transform(value: Array<WalletBalanceInterface>, args: string): any {
    if (args) args = args.toUpperCase(); else return value;

    return value.filter(item => {
      return item.currency.includes(args);
    });
  }

}
