import {Component, Inject, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import {PASSWORD_VALIDATION} from '../../app.constants';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnDestroy {
  public newPasswordForm: FormGroup;
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();
  constructor(private matDialogRef: MatDialogRef<NewPasswordComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public token: string,
              private fb: FormBuilder,
              private authService: AuthService,
              private router: Router) {
    this.createForm();

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }
  private createForm(): void {
    this.newPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern(PASSWORD_VALIDATION)]],
      passwordRepeat: ['', Validators.required]
    }, {
      validator: this.passwordMatch
    });
  }
  public changePassword(): void {
    const data = {
      password: this.newPasswordForm.value.password,
      token: this.token
    };
    this.authService.changeForgotPassword(data)
      .subscribe(
        () => {
          this.router.navigate([{ outlets: { popup: ['login'] } }]).then();
          this.matDialogRef.close();
        },
        error => {console.error(error); }
      );
  }
  private passwordMatch(group: FormGroup): void {
    const pw = group.controls['password'];
    const pwr = group.controls['passwordRepeat'];
    if (pw.value !== pwr.value) {
      pwr.setErrors({passwordMatch: true});
    } else {
      pwr.setErrors(null);
    }
  }

  get password() { return this.newPasswordForm.get('password'); }
  get passwordRepeat() { return this.newPasswordForm.get('passwordRepeat'); }
}
