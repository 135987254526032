import {OrderbooksHistoryMarketInterface, OrderbooksMarketModel} from './orderbooks-history-market.model';

export class SocketFieldClass {
  Type: number;
  Rate: number;
  Quantity: number;

  constructor(Type: number,
              Rate: number,
              Quantity: number
  ) {
    this.Type = Type;
    this.Rate = Rate;
    this.Quantity = Quantity;
  }

  static getMarketModel(v: SocketFieldClass): OrderbooksMarketModel {
    return new OrderbooksMarketModel({
      Size: v.Quantity,
      Price: v.Rate,
      Sum: v.Quantity,
      Total: v.Rate * v.Quantity,
      Type: v.Type,
    });
  }

  static fromMarketModel(v: OrderbooksMarketModel): SocketFieldClass {
    return new SocketFieldClass(v.Type, v.Price, v.Size);
  }
}

export class SocketFieldFillClass {
  FillId: number;
  FillUuid: string;
  OrderType: string;
  Quantity: number;
  Rate: number;
  TimeStamp: string;

  constructor(FillId: number,
              FillUuid: string,
              OrderType: string,
              Quantity: number,
              Rate: number,
              TimeStamp: string
  ) {
    this.FillId = FillId;
    this.FillUuid = FillUuid;
    this.OrderType = OrderType;
    this.Quantity = Quantity;
    this.Rate = Rate;
    this.TimeStamp = TimeStamp;
  }
}


export class SocketDataClass {
  Buys: Array<SocketFieldClass>;
  Fills: Array<SocketFieldFillClass>;
  Nonce: number;
  Sells: Array<SocketFieldClass>;

  constructor(Buys: Array<SocketFieldClass>,
              Fills: Array<SocketFieldFillClass>,
              Nonce: number,
              Sells: Array<SocketFieldClass>
  ) {
    this.Buys = Buys;
    this.Fills = Fills;
    this.Nonce = Nonce;
    this.Sells = Sells;
  }

  static getMarketModels(v: SocketDataClass): OrderbooksHistoryMarketInterface {
    return {
      bid: v.Buys.map((field) => SocketFieldClass.getMarketModel(field)),
      ask: v.Sells.map((field) => SocketFieldClass.getMarketModel(field)),
    };
  }

}
