import { Pipe, PipeTransform } from '@angular/core';
import {DepositWithdrawalInterface} from '../../models/deposit-withdrawal.model';
import {WalletBalanceInterface} from '../../models/wallet-balance.model';

@Pipe({
  name: 'walletHideNoTransactions'
})
export class WalletHideNoTransactionsPipe implements PipeTransform {

  transform(balances: Array<WalletBalanceInterface>, isHideNoTransactionsBal: boolean, history: Array<DepositWithdrawalInterface>): any {
    // console.log('isHideNoTransactionsBal', isHideNoTransactionsBal);
    if (isHideNoTransactionsBal) {

      if (history && history.length) {
        history.sort((a, b) => {
          if (a.currency < b.currency) { return -1; }
          if (a.currency > b.currency) { return 1; }
          return 0;
        });

        const uniqCurrenciesObj = {};
        const tempTransactions = history.filter(transaction => {
          if (uniqCurrenciesObj[transaction.currency]) {
            return false;
          }
          uniqCurrenciesObj[transaction.currency] = true;
          return true;
        });
        const uniqCurrenciesArr = Object.keys(uniqCurrenciesObj);
        // console.log(uniqCurrenciesObj, uniqCurrenciesArr);

        const balancesWithHistory = balances.filter(balance => {
          return uniqCurrenciesObj[balance.currency];
        });
        // console.log('balancesWithHistory=', balancesWithHistory.length);

        return balancesWithHistory;
      } else {
        // console.log('no history');
        return [];
      }
    } else {
      // console.log('balances=', balances.length);
      return balances;
    }
  }

}
