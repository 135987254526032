import openSocket from 'socket.io-client'
import {environment} from "../../../environments/environment";
// import {RECAPTCHA_VERSION} from "../../app/app.constants";

const Io = {
  //market: DataService.market,
  market: 'BTC-ETH',
  resolution: null,
  lastResolution: null,
  callback: null,
  lastElement: null,
  socket: null,
  params:null,
  isLoaded: false,
  // serverUrl: 'http://65.2.6.202:3333',
  serverUrl: environment.host,
  // serverUrl: 'https://api.biteeu.com',    // use it for .COM = prod
  // serverUrl: 'https://api.biteeu.com.au',    // use it for .COM.AU
  // serverUrl: ((RECAPTCHA_VERSION === 'dev' || RECAPTCHA_VERSION === 'prod')
  //             ? 'https://api.biteeu.com/'
  //             : 'https://api.biteeu.com.au/'),

  // serverUrl: environment.host.substring(0, environment.host.length-1),

  // serverUrl: 'http://bit-back.ml:3001',

  secondsInResolution: {
    '1': 60,
    '5': 300,
    '15': 900,
    '30': 1800,
    '1H': 3600,
    '2H': 7200,
    '3H': 10800,
    '4H': 14400,
    '1D': 86400,
    '3D': 259200,
    '1W': 604800,
    '1M': 2.628e+6
  },

  isUpdate(newCandleTimestamp) {
    const diffInSeconds = (newCandleTimestamp - this.lastElement.time) / 1000
    return diffInSeconds < this.secondsInResolution[this.resolution]
  },

  setSymbol(symbol) {
    // console.log('market', this.market);
    if (this.isLoaded && this.socket) {
      // console.log('market', this.market);
      this.socket.emit('leaveRoom', symbol);
      this.socket.emit('joinRoom', symbol);
    }
    this.market = symbol;
    this.isLoaded = true;
  },

  getDataByInterval: {
    '1': (ctx) => ctx.getHistory(),
    '5': (ctx) => ctx.getHistory(),
    '15': (ctx) => ctx.getFiveMinutesHistory(15),
    '30': (ctx) => ctx.getFiveMinutesHistory(30),
    '60': (ctx) => ctx.getOneHourMinutesHistory(60),
    '120': (ctx) => ctx.getOneHourMinutesHistory(120),
    '180': (ctx) => ctx.getOneHourMinutesHistory(180),
    '240': (ctx) => ctx.getOneHourMinutesHistory(240),
    '1D': (ctx) => ctx.getHistory(),
    '3D': (ctx) => ctx.getHistory(),
    '1W': (ctx) => ctx.getHistory(),
    '1M': (ctx) => ctx.getHistory(),
  },

  subscribeKline(params, callback) {
    this.params = params;
/*
    console.log('subscribeKline', params)
*/
    this.resolution = params.resolution;
    if (!this.lastResolution) {
      this.lastResolution = this.resolution;
    }

    if (!this.callback) {
      this.callback = callback
    }

    if (!this.socket) {
      this.socket = openSocket(this.serverUrl);
      this.getDataByInterval[this.resolution](this);

      this.socket.emit('joinRoom', this.market)
      this.socket.on('updateCandles', (data) => {
        if(!this.lastElement) return
        // console.log('last element');
        // console.log(this.lastElement);
        this.updateData(data);
      })

      return;
    }

    if (this.resolution !== this.lastResolution) {
      this.lastElement = null;
      this.lastResolution = this.resolution;
      this.getDataByInterval[this.resolution](this);
    }
  },

  clear() {
    this.resolution = null;
    this.lastResolution = null;
    this.callback = null;
    this.lastElement = null;
    this.params = null;
    if (this.socket) {
      this.socket.close();
      this.socket.destroy();
    }
    this.socket = null;
  },

  updateHistory(newTickData) {
    // bringMeBackForDebug console.log(newTickData, this.lastElement);
    if(this.lastElement.low > newTickData.last) this.lastElement.low = newTickData.last;
    if(this.lastElement.high < newTickData.last) this.lastElement.high = newTickData.last;
    this.lastElement.close = newTickData.last;
  },

  getNewTickTime(newTickTime) {
    // console.log('===========',newTickTime);
    const diffSeconds = (newTickTime
      - (this.lastElement.time ? this.lastElement.time : (this.lastElement.date ? Date.parse(this.lastElement.date) : this.lastElement.time))) / 1000

    return (this.lastElement.time ? this.lastElement.time : (this.lastElement.date ? Date.parse(this.lastElement.date) : this.lastElement.time))
      + (Math.floor(diffSeconds / this.secondsInResolution[this.resolution]) * this.secondsInResolution[this.resolution]) * 1000
  },

  addToHistory(newTickData) {
    // bringMeBackForDebug console.log('tick data:', newTickData);
    this.lastElement = {
      open: newTickData.last,
      high: newTickData.last,
      low: newTickData.last,
      close: newTickData.last,
      time: newTickData.time ? this.getNewTickTime(newTickData.time)
        : (newTickData.date ? this.getNewTickTime(Date.parse(newTickData.date)) : newTickData.time)
    }

    console.log('add to history', this.lastElement)
  },

  updateData(data) {
    data.time = (data.time ? data.time : (data.date ? Date.parse(data.date + 1000 * 60 * 60 * 3) : data.time));
    // bringMeBackForDebug console.log('updateData', data);
    const needUpdate = this.isUpdate(data.time);
    console.log('update data', data, needUpdate)

    if(needUpdate) {
      this.updateHistory(data)
      // console.log('updateData updateHistory')
    } else {
      this.addToHistory(data)
      // console.log('updateData addToHistory')
    }

    const dataToUpdate = this.prepareUpdateData(this.lastElement)
/*
     console.log('Io update', data)
*/
    //
    // console.log(
    //   'updateData updateTickersData params',
    //   'from ', moment(this.params.from * 1000).format('YYYY-MM-DDTHH:mm:ss'),
    //   'to ', moment(this.params.to * 1000).format('YYYY-MM-DDTHH:mm:ss'),
    //   'now', moment(dataToUpdate.kline[0].time).format('YYYY-MM-DDTHH:mm:ss'),
    //   dataToUpdate.kline[0].time,
    //   this.params.from * 1000 <= dataToUpdate.kline[0].time && dataToUpdate.kline[0].time <= this.params.to * 1000
    // )
    this.callback(dataToUpdate)
  },

  getFiveMinutesHistory(intervalNewMins) {
    const resolution = 5;

    this.getMergedHistory(resolution)
      .then((result) => {
        this.lastElement = result[result.length - 1];
        console.log('5 minutes data', result.sort((v1, v2) => v1.time > v2.time))
        this.callback(this.prepareCreateData(this.transformIntervals(result.sort((v1, v2) => v1.time > v2.time), intervalNewMins)));
      })
      .catch((error) => {
        console.log(error);
      })
  },

  getOneHourMinutesHistory(intervalNewMins) {
    const resolution = 60;

    this.getMergedHistory(resolution)
      .then((result) => {
        this.lastElement = result[result.length - 1]
        console.log('hour data', result.sort((v1, v2) => v1.time > v2.time))

        this.callback(this.prepareCreateData(this.transformIntervals(result, intervalNewMins)))
        console.log('candles data', {result, intervalNewMins}, this.transformIntervals(result, intervalNewMins))
      })
      .catch((error) => {
        console.log(error)
      })
  },

  getHistory() {
    this.getMergedHistory(this.resolution)
      .then((result) => {
        this.lastElement = result[result.length - 1]
        this.callback(this.prepareCreateData(result))
      })
      .catch((error) => {
        console.log(error)
      })
  },

  async getMergedHistory(resolution) {
    const current = await fetch(this.serverUrl + 'api/v1/bitt/candles-history-current?market=' + this.market + '&label=' + resolution)
      .then((response) => {
      // console.log('getHistory', response);
      return response.json();
    })
    const history = await fetch(this.serverUrl + 'api/v1/bitt/candles-history?market=' + this.market + '&label=' + resolution)
      .then((response) => {
      // console.log('getHistory', response);
      return response.json();
    })
    // console.log('history', [...(current || []), ...(history || [])])
    return [...(current || []), ...(history || [])];
  },

  transformIntervals(_initialDataArray, _intervalNewMins) {
    var initialDataArray = JSON.parse(JSON.stringify(this.sortHistoryDataByTime(_initialDataArray)));
    // try {
      const millisecondsInOneMin = 60000;
      const intervalNewMins = _intervalNewMins;
      const stepMilliseconds = intervalNewMins * millisecondsInOneMin;
      let result = [];
      // console.log('  ----------  ');
      // console.log('first ', new Date(initialDataArray[0].time).toISOString());
      // console.log('last ', new Date(initialDataArray[initialDataArray.length-1].time).toISOString());
      var resultDataArray = [];
      const start_obj = initialDataArray[0];
      const end_obj = initialDataArray[initialDataArray.length-1];
      let intervalsArr = [];
      intervalsArr.push(start_obj.time ? start_obj.time : (start_obj.date ? Date.parse(start_obj.date) : start_obj.time));
      let tempStartInterval = +start_obj.time ? start_obj.time : (start_obj.date ? Date.parse(start_obj.date) : start_obj.time);

    // creat array with intervals using step
      while (tempStartInterval <= (end_obj.time ? end_obj.time : (end_obj.date ? Date.parse(end_obj.date) : end_obj.time))) {
        intervalsArr.push(tempStartInterval + stepMilliseconds);
        tempStartInterval += stepMilliseconds;
      }
    // console.log('intervalsArr', intervalsArr);

      let totalTreatedLength = 0;  // check myself
      tempStartInterval = intervalsArr[0];
      for (let i = 1; i < intervalsArr.length; i++) {
        const tempEndInterval = intervalsArr[i];
        let objForSelectedInterval = {
          open: undefined,
          close: undefined,
          date: '',
          time: undefined,
          high: undefined,
          low: undefined
        };
        let dataForSelectedIntervalArr = initialDataArray.filter(item => {
          return ((item.time ? item.time : (item.date ? Date.parse(item.date) : item.time)) >= tempStartInterval)
          && ((item.time ? item.time : (item.date ? Date.parse(item.date) : item.time)) < tempEndInterval)
        });
        dataForSelectedIntervalArr = JSON.parse(JSON.stringify(this.sortHistoryDataByTime(dataForSelectedIntervalArr)));

        if (dataForSelectedIntervalArr && dataForSelectedIntervalArr.length) {
          const lastElement = dataForSelectedIntervalArr[dataForSelectedIntervalArr.length - 1];
          objForSelectedInterval.open = dataForSelectedIntervalArr[0].open;
          objForSelectedInterval.close = lastElement.close;
          objForSelectedInterval.date = lastElement.date;
          objForSelectedInterval.time = lastElement.time ? lastElement.time : (lastElement.date ? Date.parse(lastElement.date) : lastElement.time);
          objForSelectedInterval.high = Math.max.apply(Math, dataForSelectedIntervalArr.map(o => o.high));
          objForSelectedInterval.low = Math.min.apply(Math, dataForSelectedIntervalArr.map(o => o.low));

          // totalTreatedLength += dataForSelectedIntervalArr.length;

          // check my-self for using all data - delete taken obj from initial arr
          // for (let j = 0; j < dataForSelectedIntervalArr.length; j++) {
          //   initialDataArray = initialDataArray.filter(obj => {
          //     return obj.time !== dataForSelectedIntervalArr[j].time;
          //   });
          // }

          resultDataArray.push(objForSelectedInterval);
          tempStartInterval = tempEndInterval;
          // console.log(objForSelectedInterval);
          // console.log(dataForSelectedIntervalArr);
        }
        // console.log(resultDataArray);
      }
      // console.log('intervalsArr', intervalsArr);
      // console.log('totalTreatedLength', totalTreatedLength);
      // console.log('initialDataArray', initialDataArray);
      // console.log('resultDataArray', resultDataArray);
      return resultDataArray;
    // } catch (e) {
    //   return initialDataArray;
    //   console.log(e);
    // }
  },

  sortHistoryDataByTime(arr) {
    return arr.sort((a, b) => {
      if ((a.time ? a.time : (a.date ? Date.parse(a.date) : a.time)) < (b.time ? b.time : (b.date ? Date.parse(b.date) : b.time))) {
        return -1;
      }
      if ((a.time ? a.time : (a.date ? Date.parse(a.date) : a.time)) > (b.time ? b.time : (b.date ? Date.parse(b.date) : b.time))) {
        return 1;
      }
      return 0;
    })
  },

  prepareCreateData(data) {
    return {
      kline: data,
      symbol: this.market,
      resolution: this.resolution,
      type: 'kline'
    }
  },

  prepareUpdateData(data) {
    return {
      kline: data,
      symbol: this.market,
      resolution: this.resolution,
      type: 'update'
    }
  },

  getResolution() {
    return this.resolution
  },
}
export default Io
