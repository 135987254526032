import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountSort'
})
export class AmountSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction)
      return value.sort((a, b) => {
        if (direction === 'up')
          return a.amount - b.amount;
        else
          return b.amount - a.amount;
      });
    return value;
  }

}
