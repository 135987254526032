import { Injectable } from '@angular/core';
import { observable, action } from 'mobx';

@Injectable({
  providedIn: 'root',
})
export class MobileApplicationState {
  @observable currentMobileApplicationState = false;

  @action setMobileApplicationState(state: boolean) {
    this.currentMobileApplicationState = state;
  }

  getMobileApplicationState(): boolean {
    return this.currentMobileApplicationState;
  }
}
