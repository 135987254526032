import {CurrencyTagClass} from './models/currency-tag.class';
import {RestrictedCountriesClass} from './models/restricted-countries.class';

export const IS_GEO_TURNED_ON = true; // prod
// export const IS_GEO_TURNED_ON = false;   // localhost

// export const RECAPTCHA_VERSION = 'prod-au';   // use it for .com.AU
// export const RECAPTCHA_VERSION = 'prod';      // use it for .com
// export const RECAPTCHA_VERSION = 'dev';       // use it for .ml and localhost

export const USERS_AUTHORISED_FOR_FIAT_MODAL = [50, 731, 733, 735, 736, 737, 745, 746];
// 736 biteeu.au1@rambler.ru
// 737 biteeu.au2@rambler.ru
export const USERS_AUTHORISED_FOR_STOP_LOSS = []; //  remove this line if you want to define authorised user
// export const USERS_AUTHORISED_FOR_STOP_LOSS = [50, 370, 745, 746, 229]; //  remove this line if you want to define authorised user
// 229 Diazz.turdiyev@gmail.com
// 370 nurken13101976@gmail.com


export const ORDER_STOP_LIMIT = 'Stop Limit';
export const ORDER_STATUS_OPEN = 'OPEN';
export const ORDER_STATUS_FAILED = 'FAILED';
export const ORDER_STATUS_COMPLETED = 'COMPLETED';

export const MOBILE_VALIDATION = /^\(?\+?[0-9]{2,6}\)?[\s-]?[\d]{2,3}[\s-]?[\d]{2,3}[\s-]?[\d]{2,3}[\s-]?[\d]{2,9}$/;
export const PASSWORD_VALIDATION = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_+=;:,./?\\|`~\[\]{}\^'"<>~])(?=.{6,})/;
export const EMAIL_VALIDATION = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2}|aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|lat|travel)$/;


export const CURRENCIES_WITH_TAG = [
  new CurrencyTagClass('XRP', 'Tag'),
  new CurrencyTagClass('XMR', 'Payment ID'),
  new CurrencyTagClass('XLM', 'Payment ID'),
  new CurrencyTagClass('ATOM', 'MEMO'),
  new CurrencyTagClass('XEM', 'Message'),
  new CurrencyTagClass('ARDR', 'Message'),
  new CurrencyTagClass('BTS', 'MEMO'),
  new CurrencyTagClass('STEEM', 'MEMO'),
  new CurrencyTagClass('BURST', 'Message'),
  new CurrencyTagClass('EOS', 'MEMO'),
  new CurrencyTagClass('HIVE', 'MEMO'),
];


export const RESTRICTED_COUNTRIES = [
  new RestrictedCountriesClass('Iran', 'IR'),
  new RestrictedCountriesClass('Iraq', 'IQ'),
  new RestrictedCountriesClass('North Korea', 'KP'),
  new RestrictedCountriesClass('Syria', 'SY'),
  new RestrictedCountriesClass('Afghanistan', 'AF'),
  new RestrictedCountriesClass('Yemen', 'YE'),
  new RestrictedCountriesClass('Zimbabwe', 'ZW'),
  new RestrictedCountriesClass('Sudan (North)', 'SD'),
  // new RestrictedCountriesClass('Ukraine', 'UA'),
  new RestrictedCountriesClass('Somalia', 'SO'),
  new RestrictedCountriesClass('Nicaragua', 'NI'),
  new RestrictedCountriesClass('Libya', 'LY'),
  new RestrictedCountriesClass('Congo (Democratic Republic)', 'CD'),
  new RestrictedCountriesClass('United States of America', 'US'),
  new RestrictedCountriesClass('Cuba', 'CU'),
  new RestrictedCountriesClass('Burundi', 'BI'),
  new RestrictedCountriesClass('Venezuela', 'VE'),
  // new RestrictedCountriesClass('Bosnia and Herzegovina', 'BA'),
  // new RestrictedCountriesClass('Central African Republic', 'CF'),
  // new RestrictedCountriesClass('Lebanon', 'LB'),
  // new RestrictedCountriesClass('Myanmar', 'MM'),
  // new RestrictedCountriesClass('South Sudan', 'SS'),
  // new RestrictedCountriesClass('Haiti', 'HT'),
  // new RestrictedCountriesClass('Guinea', 'GN'),
  // new RestrictedCountriesClass('Mali', 'ML'),
  // new RestrictedCountriesClass('Belarus', 'BY'),
  // new RestrictedCountriesClass('Moldova', 'MD'),
  // new RestrictedCountriesClass('Serbia', 'RS'),
  // new RestrictedCountriesClass('Montenegro', 'ME'),
  // new RestrictedCountriesClass('Slovenia', 'SI'),
  // new RestrictedCountriesClass('Egypt', 'EG'),
  // new RestrictedCountriesClass('Maldives', 'MV')
];

export const RESTRICTED_CITIES = [
  // "Almaty",
  "Sevastopol",
  "Simferopol",
  "Kerch",
  "Yevpatoria",
  "Yalta",
  "Feodosia",
  "Dzhankoi",
  "Alushta",
  "Bakhchysarai",
  "Krasnoperekopsk",
  "Saky",
  "Armiansk",
  "Sudak",
  "Bilohirsk",
  "Shcholkine",
  "Inkerman",
  "Staryi Krym",
  "Alupka",
  "Bondarenkovo",
  "Verhnjaja Kutuzovka",
  "Vinogradnoe",
  "General'skoe",
  "Zaprudnoe",
  "Zelenogor'e",
  "Izobil'noe",
  "Kiparisnoe",
  "Lavanda",
  "Lavrovoe",
  "Lazurnoe",
  "Luchistoe",
  "Malorechenskoe",
  "Malyj Majak",
  "Nizhnee Zaprudnoe",
  "Nizhnjaja Kutuzovka",
  "Partenit",
  "Privetnoe",
  "Pushkino",
  "Rozovyj",
  "Rybach'e",
  "Semidvor'e",
  "Solnechnogorskoe",
  "Utjos",
  "Chajka",
  "Armjansk",
  "Voloshino",
  "Perekop",
  "Suvorovo",
  "Dzhankoj",
  "Evpatorija",
  "Zaozjornoe",
  "Mirnyj",
  "Novoozjornoe",
  "Saki",
  "Agrarnoe",
  "Ajeroflotskij",
  "Bitumnoe",
  "Grjesovskij",
  "Komsomol'skoe",
  "Bogatovka",
  "Vesjoloe",
  "Voron",
  "Gromovka",
  "Grushevka",
  "Dachnoe",
  "Lesnoe",
  "Mezhdurech'e",
  "Mindal'noe",
  "Morskoe",
  "Novyj Svet",
  "Perevalovka",
  "Pribrezhnoe",
  "Solnechnaja Dolina",
  "Holodovka",
  "Beregovoe",
  "Blizhnee",
  "Vinogradnoe",
  "Koktebel'",
  "Krasnokamenka",
  "Kurortnoe",
  "Nanikovo",
  "Nasypnoe",
  "Ordzhonikidze",
  "Pionerskoe",
  "Podgornoe",
  "Primorskij",
  "Solnechnoe",
  "Stepnoe",
  "Uzlovoe",
  "Feodosija",
  "Shhebetovka",
  "Juzhnoe",
  "Alupka",
  "Beregovoe",
  "Vinogradnoe",
  "Voshod",
  "Vysokogornoe",
  "Gaspra",
  "Goluboj Zaliv",
  "Gornoe",
  "Gurzuf",
  "Danilovka",
  "Kaciveli",
  "Koreiz",
  "Krasnokamenka",
  "Kujbyshevo",
  "Kurpaty",
  "Livadija",
  "Linejnoe",
  "Massandra",
  "Nikita",
  "Oliva",
  "Opolznevoe",
  "Oreanda",
  "Otradnoe",
  "Ohotnich'e",
  "Parkovoe",
  "Partizanskoe",
  "Ponizovka",
  "Sanatornoe",
  "Simeiz",
  "Sovetskoe",
  "Foros",
  "Jalta",
  "Ajvovoe",
  "Aromat",
  "Aromatnoe",
  "Bahchisaraj",
  "Bashtanovka",
  "Belokamennoe",
  "Beregovoe",
  "Bogatoe Ushhel'e",
  "Bogatyr'",
  "Bol'shoe Sadovoe",
  "Brjanskoe",
  "Verhorech'e",
  "Viktorovka",
  "Vilino",
  "Vysokoe",
  "Glubokij Jar",
  "Golubinka",
  "Gorka",
  "Dachnoe",
  "Dolinnoe",
  "Dorozhnoe",
  "Dubrovka",
  "Zheleznodorozhnoe",
  "Zavetnoe",
  "Zalesnoe",
  "Zeljonoe",
  "Zubakino",
  "Kazanki",
  "Kashtany",
  "Kochergino",
  "Krasnaja Zarja",
  "Krasnyj Mak",
  "Kudrino",
  "Kujbyshevo",
  "Malinovka",
  "Malovidnoe",
  "Maloe Sadovoe",
  "Mashino",
  "Mnogorech'e",
  "Mostovoe",
  "Nagornoe",
  "Nauchnyj",
  "Nekrasovka",
  "Nizhnjaja Golubinka",
  "Noven'koe",
  "Novovasil'evka",
  "Novopavlovka",
  "Novopol'e",
  "Novoul'janovka",
  "Otradnoe",
  "Peschanoe",
  "Plodovoe",
  "Plotinnoe",
  "Poljana",
  "Pochtovoe",
  "Prijatnoe Svidanie",
  "Prohladnoe",
  "Putilovka",
  "Predushhel'noe",
  "Rassadnoe",
  "Rastushhee",
  "Repino",
  "Rechnoe",
  "Rozovoe",
  "Samohvalovo",
  "Sevast'janovka",
  "Sinapnoe",
  "Siren'",
  "Skalistoe",
  "Sokolinoe",
  "Solnechnosel'e",
  "Stal'noe",
  "Suvorovo",
  "Schastlivoe",
  "Tabachnoe",
  "Tankovoe",
  "Tenistoe",
  "Topoli",
  "Trudoljubovka",
  "Turgenevka",
  "Uglovoe",
  "Furmanovka",
  "Hodzha-Sala",
  "Holmovka",
  "Shevchenkovo",
  "Aleksandrovka",
  "Alekseevka",
  "Annovka",
  "Aromatnoe",
  "Balanovo",
  "Balki",
  "Barabanovo",
  "Belaja Skala",
  "Belogorsk",
  "Bogatoe",
  "Vasil'evka",
  "Verhnie Oreshniki",
  "Vishennoe",
  "Vishnjovoe",
  "Vladimirovka",
  "Golovanovka",
  "Gorlinka",
  "Divnoe",
  "Dozornoe",
  "Dolinovka",
  "Elenovka",
  "Zelenogorskoe",
  "Zemljanichnoe",
  "Zuja",
  "Zybiny",
  "Karasjovka",
  "Kizilovka",
  "Kirpichnoe",
  "Krasnaja Sloboda",
  "Krasnogorskoe",
  "Krasnosjolovka",
  "Krivcovo",
  "Krinichnoe",
  "Krymskaja Roza",
  "Kurortnoe",
  "Kurskoe",
  "Lechebnoe",
  "Litvinenkovo",
  "Lugovoe",
  "Malinovka",
  "Mezhgor'e",
  "Melehovo",
  "Mel'niki",
  "Mel'nichnoe",
  "Mironovka",
  "Michurinskoe",
  "Muromskoe",
  "Nekrasovo",
  "Nizhnie Oreshniki",
  "Novoaleksandrovka",
  "Novogrigor'evka",
  "Novozhilovka",
  "Novokljonovo",
  "Ovrazhki",
  "Opytnoe",
  "Pavlovka",
  "Pasechnoe",
  "Petrovo",
  "Povorotnoe",
  "Prolom",
  "Pchelinoe",
  "Radostnoe",
  "Rodniki",
  "Rusakovka",
  "Russkoe",
  "Severnoe",
  "Sennoe",
  "Sinekamenka",
  "Topolevka",
  "Turgenevo",
  "Udarnoe",
  "Ukrainka",
  "Ul'janovka",
  "Uchebnoe",
  "Cheremisovka",
  "Hlebnoe",
  "Cvetochnoe",
  "Chernopol'e",
  "Jablochnoe",
  "Jakovlevka",
  "Azovskoe",
  "Antonovka",
  "Aprelevka",
  "Arbuzovka",
  "Armejskoe",
  "Blagodatnoe",
  "Blizhnegorodskoe",
  "Blizhnee",
  "Bolotnoe",
  "Borodino",
  "Vesjoloe",
  "Vetvistoe",
  "Volodino",
  "Vol'noe",
  "Vypasnoe",
  "Dmitrievka",
  "Dneprovka",
  "Dorozhnoe",
  "Dubrovka",
  "Ermakovo",
  "Zhilino",
  "Zavet-Leninskij",
  "Zarechnoe",
  "Zeljonyj Jar",
  "Zernovoe",
  "Izumrudnoe",
  "Istochnoe",
  "Kalinovka",
  "Kovyl'noe",
  "Koloski",
  "Komsomol'skoe",
  "Kondrat'evo",
  "Konstantinovka",
  "Kopani",
  "Krasnodol'noe",
  "Krymka",
  "Larino",
  "Lobanovo",
  "Luganskoe",
  "Majskoe",
  "Martynovka",
  "Mar'ino",
  "Maslovo",
  "Medvedevka",
  "Melkovodnoe",
  "Mirnovka",
  "Mitjurino",
  "Michurinovka",
  "Mnogovodnoe",
  "Mysovoe",
  "Nahodka",
  "Nizhnie Otrozhki",
  "Nizinnoe",
  "Novaja Zhizn'",
  "Novokonstantinovka",
  "Novokrymskoe",
  "Novopavlovka",
  "Novosel'cevo",
  "Novostepnoe",
  "Novofjodorovka",
  "Ovoshhnoe",
  "Ozerki",
  "Ozjornoe",
  "Oktjabr'",
  "Ordenonosnoe",
  "Ostrovskoe",
  "Otradnoe",
  "Pavlovka",
  "Paharevka",
  "Perepjolkino",
  "Pobednoe",
  "Polevoe",
  "Predmostnoe",
  "Pridorozhnoe",
  "Probuzhdenie",
  "Prozrachnoe",
  "Prostornoe",
  "Pushkino",
  "Rodnoe",
  "Roskoshnoe",
  "Roshhino",
  "Rubinovka",
  "Rysakovo",
  "Rjumshino",
  "Svetloe",
  "Sernovodskoe",
  "Slavjanka",
  "Slavjanskoe",
  "Smezhnoe",
  "Sovetskoe",
  "Soljonoe Ozero",
  "Soloncovoe",
  "Stal'noe",
  "Stefanovka",
  "Stolbovoe",
  "Subbotnik",
  "Tabachnoe",
  "Tarasovka",
  "Timirjazevo",
  "Timofeevka",
  "Tomashevka",
  "Turgenevo",
  "Tutovoe",
  "Udarnoe",
  "Fjodorovka",
  "Hlebnoe",
  "Celinnoe",
  "Chajkino",
  "Jarkoe",
  "Jarkoe Pole",
  "Jasnoe",
  "Jasnopoljanskoe",
  "Jastrebcy",
  "Abrikosovka",
  "Ajvazovskoe",
  "Babenkovo",
  "Vasil'kovoe",
  "Vidnoe",
  "Vladislavovka",
  "Vozrozhdenie",
  "Dobroljubovka",
  "Dolinnoe",
  "Zhemchuzhina Kryma",
  "Zhuravki",
  "Zolotoe Pole",
  "Izobil'noe",
  "Izjumovka",
  "Kirovskoe",
  "Kljuchevoe",
  "Krasnovka",
  "Krasnosel'skoe",
  "Krinichki",
  "L'govskoe",
  "Makovskoe",
  "Matrosovka",
  "Novopokrovka",
  "Novofjodorovka",
  "Orehovka",
  "Otvazhnoe",
  "Partizany",
  "Pervomajskoe",
  "Privetnoe",
  "Prudy",
  "Sadovoe",
  "Sinicyno",
  "Sofievka",
  "Spasovka",
  "Staryj Krym",
  "Tokarevo",
  "Trudoljubovka",
  "Tutovka",
  "Shubino",
  "Jarkoe Pole",
  "Azov",
  "Aleksandrovka",
  "Amurskoe",
  "Blizhnee",
  "Vidnoe",
  "Vishnjakovka",
  "Vladimirovo",
  "Voshod",
  "Grigor'evka",
  "Dokuchaevo",
  "Dohodnoe",
  "Dubrovskoe",
  "Zarechnoe",
  "Zarja",
  "Zvjozdnoe",
  "Zernovoe",
  "Znamenka",
  "Zolotoe",
  "Izvestkovoe",
  "Iskra",
  "Kalinino",
  "Karpovka",
  "Klepinino",
  "Klimovo",
  "Kolodeznoe",
  "Komarovka",
  "Kommunary",
  "Kotel'nikovo",
  "Krasnaja Dolina",
  "Krasnaja Poljana",
  "Krasnogvardejskoe",
  "Krasnodarka",
  "Krasnoznamenka",
  "Krasnyj Partizan",
  "Kremnevka",
  "Kurgannoe",
  "Leninskoe",
  "Mar'janovka",
  "Mashino",
  "Mendeleevo",
  "Miroljubovka",
  "Mironovka",
  "Molochnoe",
  "Muskatnoe",
  "Najdjonovka",
  "Nahimovo",
  "Nevskoe",
  "Nekrasovo",
  "Novoalekseevka",
  "Novodolinka",
  "Novoekaterinovka",
  "Novozuevka",
  "Novoivanovka",
  "Novonikol'skoe",
  "Novopokrovka",
  "Novosel'cy",
  "Novojestonija",
  "Oktjabr'skoe",
  "Orlovka",
  "Petrovka",
  "Plodorodnoe",
  "Pobedino",
  "Pologi",
  "Poltavka",
  "Protochnoe",
  "Prjamoe",
  "Pushkino",
  "Pjatihatka",
  "Raduzhnoe",
  "Rovnoe",
  "Rogovo",
  "Salgirka",
  "Simonenko",
  "Timashovka",
  "Timoshenko",
  "Traktovoe",
  "Udachnoe",
  "Ul'janovka",
  "Holmovoe",
  "Cvetkovo",
  "Chapaevo",
  "Shherbakovo",
  "Jantarnoe",
  "Jastrebovka",
  "Bogachjovka",
  "Bratskoe",
  "Vishnjovka",
  "Voinka",
  "Voroncovka",
  "Dolinka",
  "Zeljonaja Niva",
  "Znamenka",
  "Il'inka",
  "Istochnoe",
  "Ishun'",
  "Karpova Balka",
  "Krasnoarmejskoe",
  "Krepkoe",
  "Kurgannoe",
  "Magazinka",
  "Nadezhdino",
  "Novoaleksandrovka",
  "Novoivanovka",
  "Novonikolaevka",
  "Novopavlovka",
  "Novorybackoe",
  "Orlovskoe",
  "Poltavskoe",
  "Pochjotnoe",
  "Privol'noe",
  "Proletarka",
  "Pjatihatka",
  "Risovoe",
  "Svatovo",
  "Smushkino",
  "Sovhoznoe",
  "Tavricheskoe",
  "Tankovoe",
  "Traktovoe",
  "Utkino",
  "Filatovka",
  "Shatry",
  "Azovskoe",
  "Bagerovo",
  "Batal'noe",
  "Belinskoe",
  "Bondarenkovo",
  "Borisovka",
  "Verhnezamorskoe",
  "Vinogradnoe",
  "Vojkovo",
  "Vulkanovka",
  "Vjaznikovo",
  "Glazovka",
  "Gornostaevka",
  "Egorovo",
  "Erofeevo",
  "Zavetnoe",
  "Zavodskoe",
  "Zatishnoe",
  "Zeljonyj Jar",
  "Zolotoe",
  "Ivanovka",
  "Il'ichjovo",
  "Kalinovka",
  "Kamenskoe",
  "Kirovo",
  "Koroljovo",
  "Kostyrino",
  "Krasnogorka",
  "Kurortnoe",
  "Lenino",
  "Leninskoe",
  "Libknehtovka",
  "Lugovoe",
  "L'vovo",
  "Marfovka",
  "Mar'evka",
  "Mysovoe",
  "Naberezhnoe",
  "Nizhnezamorskoe",
  "Novonikolaevka",
  "Novootradnoe",
  "Novosjolovka",
  "Ogon'ki",
  "Oktjabr'skoe",
  "Osoviny",
  "Ostanino",
  "Pesochnoe",
  "Petrovo",
  "Priozjornoe",
  "Prudnikovo",
  "Ptashkino",
  "Romanovo",
  "Semjonovka",
  "Semisotka",
  "Soljanoe",
  "Stancionnoe",
  "Tasunovo",
  "Uvarovo",
  "Fontan",
  "Frontovoe",
  "Cheljadinovo",
  "Chistopol'e",
  "Shhjolkino",
  "Juzhnoe",
  "Jurkino",
  "Jakovenkovo",
  "Jarkoe",
  "Jachmennoe",
  "Akimovka",
  "Burevestnik",
  "Velikosel'e",
  "Vladislavovka",
  "Dvorovoe",
  "Dvurech'e",
  "Drofino",
  "Emel'janovka",
  "Zheljabovka",
  "Zhemchuzhina",
  "Zalivnoe",
  "Zarech'e",
  "Zeljonoe",
  "Zorkino",
  "Ivanovka",
  "Izobil'noe",
  "Kirsanovka",
  "Kovrovo",
  "Korennoe",
  "Kostochkovka",
  "Kukuruznoe",
  "Kuncevo",
  "Linejnoe",
  "Listvennoe",
  "Lomonosovo",
  "Lugovoe",
  "Luzhki",
  "Ljubimovka",
  "Mezhevoe",
  "Mitrofanovka",
  "Mihajlovka",
  "Nezhinskoe",
  "Nizhnegorskij",
  "Novogrigor'evka",
  "Novoivanovka",
  "Ohotskoe",
  "Peny",
  "Plodovoe",
  "Prirechnoe",
  "Pshenichnoe",
  "Razlivy",
  "Rodniki",
  "Sadovoe",
  "Semennoe",
  "Serovo",
  "Slivjanka",
  "Stepanovka",
  "Strepetovo",
  "Tambovka",
  "Tarasovka",
  "Uvarovka",
  "Ujutnoe",
  "Frunze",
  "Cvetushhee",
  "Chervonoe",
  "Chkalovo",
  "Shirokoe",
  "Jastrebki",
  "Abrikosovo",
  "Alekseevka",
  "Arbuzovo",
  "Bratskoe",
  "Vojkovo",
  "Vypasnoe",
  "Gvardejskoe",
  "Grishino",
  "Dal'nee",
  "Dmitrovka",
  "Elenovka",
  "Kalinino",
  "Kamenka",
  "Kashtanovka",
  "Kormovoe",
  "Krest'janovka",
  "Krylovka",
  "Levitanovka",
  "Makarovka",
  "Matveevka",
  "Mel'nichnoe",
  "Novaja Derevnja",
  "Oktjabr'skoe",
  "Ostrovskoe",
  "Otkrytoe",
  "Panfilovka",
  "Pervomajskoe",
  "Pravda",
  "Privol'noe",
  "Pshenichnoe",
  "Rovnoe",
  "Sary-Bash",
  "Sverdlovskoe",
  "Snegirjovka",
  "Stahanovka",
  "Stepnoe",
  "Susanino",
  "Tihonovka",
  "Upornoe",
  "Frunze",
  "Chapaevo",
  "Chernovo",
  "Avrora",
  "Bahchjovka",
  "Berjozovka",
  "Botanicheskoe",
  "Vetrjanka",
  "Volochaevka",
  "Voronki",
  "Zimino",
  "Kamyshnoe",
  "Kashtanovka",
  "Kovyl'noe",
  "Kommunarnoe",
  "Kotovskoe",
  "Krasnoarmejskoe",
  "Kropotkino",
  "Kukushkino",
  "Kumovo",
  "Maksimovka",
  "Molochnoe",
  "Niva",
  "Novosjolovskoe",
  "Ovrazhnoe",
  "Ogni",
  "Ogorodnoe",
  "Orlovka",
  "Portovoe",
  "Razdol'noe",
  "Ruch'i",
  "Ryleevka",
  "Severnoe",
  "Senokosnoe",
  "Serebrjanka",
  "Slavnoe",
  "Slavjanskoe",
  "Sokoly",
  "Steregushhee",
  "Ul'janovka",
  "Fjodorovka",
  "Chervonoe",
  "Chernyshjovo",
  "Chehovo",
  "Abrikosovka",
  "Valentinovo",
  "Velikoe",
  "Veresaevo",
  "Vershinnoe",
  "Vesjolovka",
  "Vetrovka",
  "Vinogradovo",
  "Vitino",
  "Vladimirovka",
  "Vlastnoe",
  "Vodopojnoe",
  "Vorob'jovo",
  "Garshino",
  "Gerojskoe",
  "Glinka",
  "Gromovka",
  "Dobrushino",
  "Dolinka",
  "Elizavetovo",
  "Zhavoronki",
  "Zheltokamenka",
  "Zhuravli",
  "Zernovoe",
  "Ivanovka",
  "Igorevka",
  "Izvestkovoe",
  "Il'inka",
  "Kamenolomnja",
  "Kar'ernoe",
  "Koloski",
  "Kol'covo",
  "Krajnee",
  "Krylovka",
  "Krymskoe",
  "Kulikovka",
  "Lesnovka",
  "Limannoe",
  "Listovoe",
  "Lugovoe",
  "Lushino",
  "Mitjaevo",
  "Mihajlovka",
  "Molochnoe",
  "Natashino",
  "Naumovka",
  "Niva",
  "Nizinnoe",
  "Novofjodorovka",
  "Ognevoe",
  "Orehovo",
  "Orljanka",
  "Ohotnikovo",
  "Pobednoe",
  "Popovka",
  "Porfir'evka",
  "Pribrezhnoe",
  "Privetnoe",
  "Romashkino",
  "Runnoe",
  "Sizovka",
  "Soldatskoe",
  "Stepnoe",
  "Stolbovoe",
  "Suvorovskoe",
  "Trudovoe",
  "Tunnel'noe",
  "Ujutnoe",
  "Frunze",
  "Furmanovo",
  "Hutorok",
  "Chebotarka",
  "Chervonnoe",
  "Shalashi",
  "Shaumjan",
  "Shelkovichnoe",
  "Shishkino",
  "Shtormovoe",
  "Jarkoe",
  "Ajkavan",
  "Akropolis",
  "Aleksandrovka",
  "Ana-Jurt",
  "Andrusovo",
  "Arkad'evka",
  "Beloglinka",
  "Verhnekurgannoe",
  "Vesjoloe",
  "Vinnickoe",
  "Vodnoe",
  "Gvardejskoe",
  "Grushevoe",
  "Davydovo",
  "Dem'janovka",
  "Denisovka",
  "Divnoe",
  "Dmitrovo",
  "Dobroe",
  "Donskoe",
  "Druzhnoe",
  "Dubki",
  "Zhivopisnoe",
  "Zhuravljovka",
  "Zales'e",
  "Zarechnoe",
  "Ivanovka",
  "Kamyshinka",
  "Kashtanovoe",
  "Kizilovoe",
  "Kljonovka",
  "Klinovka",
  "Kljuchevoe",
  "Kljuchi",
  "Kolodeznoe",
  "Kol'chugino",
  "Konstantinovka",
  "Krasnaja Zor'ka",
  "Krasnovka",
  "Krasnoe",
  "Krasnoles'e",
  "Kubanskoe",
  "Kuprino",
  "Kurgannoe",
  "Lazarevka",
  "Levadki",
  "Lekarstvennoe",
  "Lesnosel'e",
  "Lozovoe",
  "Mazanka",
  "Malen'koe",
  "Mezhgornoe",
  "Mirnoe",
  "Molodjozhnoe",
  "Molochnoe",
  "Mramornoe",
  "Nizhnekurgannoe",
  "Nikolaevka",
  "Novoandreevka",
  "Novozbur'evka",
  "Novonikolaevka",
  "Novosjolovka",
  "Novyj Mir",
  "Novyj Sad",
  "Obryv",
  "Opushki",
  "Partizanskoe",
  "Pervomajskoe",
  "Pereval'noe",
  "Peredovoe",
  "Perovo",
  "Petrovka",
  "Petropavlovka",
  "Pionerskoe",
  "Pozharskoe",
  "Privol'noe",
  "Proljotnoe",
  "Prudovoe",
  "Ravnopol'e",
  "Razdol'e",
  "Rodnikovo",
  "Skvorcovo",
  "Sovhoznoe",
  "Solnechnoe",
  "Solov'jovka",
  "Sofievka",
  "Spokojnoe",
  "Storozhevoe",
  "Strogonovka",
  "Sumskoe",
  "Suhorech'e",
  "Teplovka",
  "Tjoploe",
  "Topol'noe",
  "Trjohprudnoe",
  "Trudovoe",
  "Trudoljubovo",
  "Ukrainka",
  "Ukromnoe",
  "Urozhajnoe",
  "Fersmanovo",
  "Fontany",
  "Haritonovka",
  "Chajkino",
  "Chajkovskoe",
  "Chisten'koe",
  "Shafrannoe",
  "Shirokoe",
  "Shkol'noe",
  "Almaznoe",
  "Varvarovka",
  "Vostochnoe",
  "Georgievka",
  "Dem'janovka",
  "Dmitrovka",
  "Djatlovka",
  "Zavetnoe",
  "Il'ichjovo",
  "Kolomenskoe",
  "Korneevka",
  "Krasnogvardejskoe",
  "Krasnoflotskoe",
  "Lebedinka",
  "Lohovka",
  "Luchevoe",
  "Makovka",
  "Markovo",
  "Nadezhda",
  "Nekrasovka",
  "Nikolaevka",
  "Novosjolovka",
  "Novyj Mir",
  "Oktjabr'skoe",
  "Privol'noe",
  "Prisivashnoe",
  "Prudy",
  "Pushkino",
  "Pchel'niki",
  "Razdol'noe",
  "Rechnoe",
  "Rovenka",
  "Sovetskij",
  "Urozhajnoe",
  "Hlebnoe",
  "Chapaevka",
  "Chernozjomnoe",
  "Shahtino",
  "Artjomovka",
  "Vladimirovka",
  "Vnukovo",
  "Vodopojnoe",
  "Gromovo",
  "Daljokoe",
  "Dozornoe",
  "Zhuravljovka",
  "Zadornoe",
  "Zajcevo",
  "Znamenskoe",
  "Zorjanoe",
  "Kalinovka",
  "Kirovskoe",
  "Krasnaja Poljana",
  "Krasnosel'skoe",
  "Krasnojarskoe",
  "Kuzneckoe",
  "Lenskoe",
  "Mar'ino",
  "Majak",
  "Medvedevo",
  "Mezhvodnoe",
  "Nizovka",
  "Novoivanovka",
  "Novosel'skoe",
  "Novoul'janovka",
  "Ozjorovka",
  "Okunjovka",
  "Olenevka",
  "Severnoe",
  "Snezhnoe",
  "Hmelevo",
  "Chernomorskoe",
];

// Balkans (Moldova, Belorus)		???
// Crimea, Ukraine 		???
// Burma		???
//   Ukraine		UA
// ISIL and Al-Qaida		???
// Russian Federation (Crimea)	7/31/2019	???
// Tinisia		???
