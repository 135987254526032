import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'redemptionHistory'
})
export class RedemptionHistoryPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    value.sort((a, b) => {
      if (direction === 'up') {
        return Date.parse(b.date) - Date.parse(a.date);
      } else if (direction === 'down') {
        return Date.parse(a.date) - Date.parse(b.date);
      }
      return value;
    }
  )
    ;
    return value;
  }
}
