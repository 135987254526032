import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SvgService } from '../../services/svg.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {
  public isLightTheme = false;
  public isSuccessPayment: boolean;
  destroySubject$: Subject<void> = new Subject();
  constructor(private matDialogRef: MatDialogRef<PaymentStatusComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public svgService: SvgService,
              public router: Router) {

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  ngOnInit() {
    this.isSuccessPayment = this.router.url.includes('ok');
  }

  public close(): void {
    this.matDialogRef.close();
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }
}
