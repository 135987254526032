import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent,
  HttpUserEvent } from '@angular/common/http';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private token;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private router: Router,
              private authService: AuthService,
              private injector: Injector) {}

  private addToken(req): HttpRequest<any> {
    this.token = this.authService.getAuthorizationHeader();
    if (this.token) {
      return req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: this.token.accessToken
        }
      });
    }

    return req;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent |
    HttpResponse<any> | HttpUserEvent<any>> {

    return next.handle(this.addToken(req))
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse) {
          return this.handleError(req, next, error);
        } else {
          return observableThrowError(error);
        }
      }));
  }

  private handleError(req: HttpRequest<any>, next: HttpHandler, error) {
    const authService = this.injector.get(AuthService);
    if (error.error.errorCode === '417_45' && this.token && authService.isLoggedIn) {
      if (!this.isRefreshingToken) {
        this.isRefreshingToken = true;
        this.tokenSubject.next(null);

        if (authService.isLoggedIn) {
          return authService.refreshToken(this.token.refreshToken)
            .pipe(switchMap(newToken => {
                if (newToken) {
                  this.authService.storeToken(newToken);
                  this.tokenSubject.next(newToken);

                  return next.handle(this.addToken(req));
                }

                return this.logout();
              }),
              catchError(err => {
                return this.logout();
              }),
              finalize(() => {
                this.isRefreshingToken = false;
              }));
        }
      } else {
        return this.tokenSubject.pipe(
          filter(token => token != null),
          take(1),
          switchMap(token => {
            return next.handle(this.addToken(req));
          }));
      }
    } else {
      if (error.error.errorCode === '417_53'
        || error.error.errorCode === '417_12'
        || error.error.errorCode === '401_2'
        || error.error.errorCode === '401_3') {
        return this.logout();
      }
      return observableThrowError(error);
    }
  }

  private logout() {
    this.authService.deleteToken();
    this.authService.isLoggedIn = false;
    //this.router.navigate(['login']).then();

    return observableThrowError('');
  }
}
