import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-crypto-credentials',
  templateUrl: './crypto-credential.component.html',
  styleUrls: ['./crypto-credential.component.scss']
})
export class CryptoCredentialComponent implements OnInit {
  isMasterCardAccountActive: boolean;
  isUserStatusFullVerified = false;

  constructor(public dataService: DataService, private http: HttpClient, private router: Router)  {
  }
  ngOnInit() {
    if (!localStorage.getItem('jwtToken')) {
      this.router.navigate(['/']);
    }
    this.checkMasterCardProfileAndFullVerifiedStatus();
    // this.dataService.checkMastercardAccountStatus('').subscribe(v => console.log(v));
    // this.http.post('https://test-api.intebix.kz/api/v1/mastercard/account', {
    //   accountAlias: 'nickname.mastercard',
    // }, options).subscribe(v => console.log(v));
  }


  checkMasterCardProfileAndFullVerifiedStatus() {
    this.dataService.getUserProfile().toPromise();
    this.dataService.getUserProfile().subscribe((userData) => {
      if (userData && userData.first_name && userData.last_name && userData.iin && userData.country_id && userData.birthday) {
        this.dataService.getUserKycStatus().subscribe(data => {
          console.log('USER STATUS FULL VERIFIED', data);
          this.isUserStatusFullVerified = data.status === 1;
        });
      }
    });
    this.dataService.getMasterCardAccountStatus().subscribe((data) => {
      if (data[0]?.status === 'ACTIVE') {
        this.isMasterCardAccountActive = true;
      }
    });
  }

  register() {
    if (this.isUserStatusFullVerified) {
      this.router.navigate(['', { outlets: { popup: ['crypto-credential-sign-up'] } }]);
    } else if (!this.isUserStatusFullVerified) {
      this.router.navigate(['/user-settings/my-profile']);
    }
  }

  toggleDropdown(e: any) {
    const activeDropdown = e.target;
    if (activeDropdown && !activeDropdown.classList.contains('dropdown-item--active')) {
      activeDropdown.classList.add('dropdown-item--active');
    } else if (activeDropdown && activeDropdown.classList.contains('dropdown-item--active')) {
      activeDropdown.classList.remove('dropdown-item--active');
    }
  }

  protected readonly localStorage = localStorage;
}
