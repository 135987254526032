import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/internal/Observable';
import {TokensInterface} from '../models/tokens.model';
import {DataService} from './data.service';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {ProfileState} from '../store/profile.state';
import {Subject} from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLoggedIn$ = new BehaviorSubject((false));
  public isTestAccount = false;
  public isRememberMe = false;
  public reCaptcha_version = environment.name || 'prod';
  constructor(public base: BaseService,
              public dataService: DataService,
              public profileState: ProfileState,
              ) {
    this.isLoggedIn = !!(sessionStorage.getItem('jwtToken') || localStorage.getItem('jwtToken'));
    this.isTestAccount = false;
  }

  private loginStatus = new Subject<boolean>();

  get loginStatus$() {
    return this.loginStatus.asObservable();
  }

  get isLoggedIn() {
    return this.isLoggedIn$.value;
  }
  set isLoggedIn(v) {
    this.isLoggedIn$.next(v);
  }

  check2FaIsEnabled(email: string): Observable<any> {
    return this.base.get(`api/v1/user/check2f/${email}`);
    //api/v1/user/check2f/{email}
  }
  login(data): Observable<any> {
    return this.base.post('login', data);
  }
  register(data): Observable<any> {
    return this.base.post('registration', data);
  }
  logout(): void {
    this.base.get('logout')
      .subscribe(() => {
        this.deleteToken();
      });
    this.isLoggedIn = false;
    this.deleteToken();
    this.deleteToken();
    this.profileState.clearUserProfile();
  }

  updateLoginStatus(status: boolean) {
    this.loginStatus.next(status);
  }

  public storeToken(tokens: TokensInterface): void {

    console.log('store token', tokens);
    sessionStorage.jwtToken = JSON.stringify(tokens);
    this.setRememberToken(tokens);
    this.isLoggedIn = true;
  }
  public setRememberToken(tokens: TokensInterface): void {
    this.deleteRememberToken();
    localStorage.setItem('jwtToken', JSON.stringify(tokens));
    this.isRememberMe = true;
  }
  public deleteRememberToken(): void {
    try {
      localStorage.removeItem('jwtToken');
    } catch (e) {}
    this.isRememberMe = false;
  }
  public deleteToken(): void {
    sessionStorage.removeItem('jwtToken');
    this.deleteRememberToken();
    this.isLoggedIn = false;
  }
  checkToken(): Observable<any> {
    return this.base.get('api/v1/user/check-status');
  }
  refreshToken(token: string): Observable<any>  {
    return this.base.post('refresh-token', {refresh_token: token});
  }
  getAuthorizationHeader(): any {
    return JSON.parse(sessionStorage.getItem('jwtToken')) || JSON.parse(localStorage.getItem('jwtToken'));
  }
  resendCheckEmail(email): Observable<any> {
    return this.base.get(`api/v1/user/resend-check-email/${encodeURIComponent(email)}`);
  }
  forgotPassword(email): Observable<any> {
    return this.base.get(`api/v1/user/forgot-password/${encodeURIComponent(email)}`);
  }
  changeForgotPassword(data): Observable<any> {
    return this.base.post('api/v1/user/forgot-password', data);
  }
  changePassword(data): Observable<any> {
    return this.base.patch('api/v1/user/change-password', data);
  }
  changePasswordWithToken(token): Observable<any> {
    return this.base.get(`change-password/${token}`);
  }
  twoFaSetup(): Observable<any> {
    return this.base.post('setup-totp', {});
  }
  toggle2Fa(code): Observable<any> {
    return this.base.post('api/v1/user/toggle-totp', {code});
  }
  login2Fa(data): Observable<any> {
    return this.base.post('login-totp', data);
  }
  loginWithEmail(token) {
    return this.base.get(`login-verify/${token}`);
  }
  validateEmail(token) {
    return this.base.get(`validate-email/${token}`);
  }
  changeUserKycStatus(action, token) {
    return this.base.get(`user-kyc/${action}/${token}`);
  }
}
