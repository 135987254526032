import {Component, forwardRef, EventEmitter, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export interface ISelectOption<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'app-chips-selector',
  templateUrl: './chips-selector.component.html',
  styleUrls: ['./chips-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChipsSelectorComponent),
    multi: true
  }]
})
export class ChipsSelectorComponent<T> implements ControlValueAccessor {

  @Input() options: ISelectOption<T>[] = [];

  @Input() set model(v: T) {
    this.value = v;
  }

  @Output() modelChange = new EventEmitter<T>();

  /**
   * Holds the current value of the slider
   */
  value: T = null;

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {
  }

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {
  }

  constructor() {
  }

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
    this.modelChange.emit(this.value);
  }

  setValue(v: T) {
    this.value = v;
    this.updateChanges();
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: T): void {
    this.value = value;
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
