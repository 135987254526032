import {Component, forwardRef, EventEmitter, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomInputComponent),
    multi: true
  }]
})
export class CustomInputComponent implements ControlValueAccessor {

  @Input() type: 'text' | 'number' = 'text';
  @Input() prefix = '';
  @Input() suffix = '';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() disabled = false;
  @Input() dark = false;
  @Input() leftAlign = false;
  @Input() readonly = false;
  @Input() regExp = null;

  @Input() set model(v) {
    this.writeValue(v);
  }
  @Output() modelChange = new EventEmitter<any>();

  @Output() input = new EventEmitter<any>();
  @Output() focusout = new EventEmitter<any>();

  /**
   * Holds the current value of the slider
   */
  value = '';

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {
  }

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {
  }

  constructor() {
  }

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  valueChange() {
    this.updateChanges();
    this.modelChange.emit(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: string): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
