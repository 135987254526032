import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'feesAndLimits'
})
export class FeesAndLimitsPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    value.sort((a, b) => {
        if (direction === 'up') {
          return b.currency - a.currency;
        } else if (direction === 'down') {
          return a.currency - b.currency;
        }
        return value;
      }
    )
    ;
    return value;
  }
}
