import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'walletOrderType'
})
export class WalletOrderTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args) return value.filter(item => {
      return item.type.toLowerCase() === args.toLowerCase();
    }); else return value;
  }

}
