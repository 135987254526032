import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'walletWithdrawDeposit'
})
export class WalletWithdrawDepositPipe implements PipeTransform {

  transform(value: any, currency: string, type: string): any {
    return `${type.toLowerCase() === 'withdrawal' ? '-' : '' }${(+value).toFixed(8)} ${currency}`;
  }
}
