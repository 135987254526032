import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'numberPlace'
})
export class NumberPlacePipe implements PipeTransform {

  numberPipe = new DecimalPipe('en-US');

  transform(value: number, places: number): string {
    const basePlaces = Math.round(value).toString().length;
    const decimalPlaces = places > basePlaces ? places - basePlaces : 0;
    const result = (Math.round(value * (10 ** decimalPlaces)) / (10 ** decimalPlaces));
    return this.numberPipe.transform(result, `1.${decimalPlaces}-${decimalPlaces}`);
  }

}

export function roundByPlaces(value: number, places: number): number {
  const basePlaces = Math.round(value).toString().length;
  const decimalPlaces = places > basePlaces ? places - basePlaces : 0;
  return(Math.round(value * (10 ** decimalPlaces)) / (10 ** decimalPlaces));
}
