import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeCurrencySort'
})
export class TypeCurrencyPipe implements PipeTransform {

  transform(value: any, direction: any): any {
    if (direction && value && value.length)
        return value.sort((a, b) => {
          if (direction === 'up') {
            if (a.currency < b.currency) return -1;
            if (a.currency > b.currency) return 1;
            return 0;
          } else if (direction === 'down') {
            if (a.currency < b.currency) return 1;
            if (a.currency > b.currency) return -1;
            return 0;
          }
        });
    return value;
  }

}
