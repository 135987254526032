import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-set-landing-language',
  template: `<div></div>`
})
export class SetLandingLanguageComponent {

  constructor(private route: ActivatedRoute, private translateService: TranslateService, private router: Router) {
    const lang = this.route.snapshot.params['lang'];
    const path = this.route.snapshot.routeConfig.path.replace('/:lang', '');
    this.translateService.use(lang);
    this.router.navigateByUrl(path).then();
  }

}
