import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'balanceSort'
})
export class BalanceSortPipe implements PipeTransform {

  transform(value: any, direction: any): any {

    if (direction) {
      return value.sort((a, b) => {
        if (direction === 'up') {
          return a.balance - b.balance;
        } else {
          return b.balance - a.balance;
        }
      });
    }
    return value;
  }

}
