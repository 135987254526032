import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export interface ISelectOption<T> {
  label: string;
  value: T;
  iconSrc?: string;
}

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSelectComponent),
    multi: true
  }]
})
export class CustomSelectComponent<T> implements OnInit, ControlValueAccessor, OnDestroy {

  @Input('options') set setOptions(data:  ISelectOption<T>[]) {
    this.options = data;
    this.optionsMap = new Map(data.map((v) => [v.value, v]));
  }
  @Input() label = '';

  options: ISelectOption<T>[] = [];
  control = new FormControl('');

  optionsMap: Map<T, ISelectOption<T>> = new Map([]);

  disabled = false;

  private _destroy$ = new Subject();

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {
  }

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(
      (v) => this.onChange(v),
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  writeValue(obj: T): void {
    this.control.patchValue(obj);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
