import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-twofa-check',
  templateUrl: './twofa-check.component.html',
  styleUrls: ['./twofa-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwofaCheckComponent implements OnDestroy {

  twoFaCodeStr: string;
  agreement = false;

  waitForCheck = false;
  isWrongCode = false;

  private _destroyer = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<TwofaCheckComponent>,
              private _dataService: DataService,
              private _cdRef: ChangeDetectorRef) { }

  ngOnDestroy(): void {
    this._destroyer.next();
    this._destroyer.complete();
  }

  submit(): void {
    this.isWrongCode = false;
    this.waitForCheck = true;
    this.dialogRef.disableClose = true;
    this._dataService
      .checkTwoFa(this.twoFaCodeStr)
      .pipe(takeUntil(this._destroyer))
      .subscribe(result => {
        if (result) {
          this.dialogRef.close(true);
        } else {
          this.isWrongCode = true;
        }
      })
      .add(() => {
        this.waitForCheck = false;
        this.dialogRef.disableClose = false;
        this._cdRef.markForCheck();
      });
  }
}
