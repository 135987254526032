import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import {MatDialog} from '@angular/material/dialog';
import {AlertComponent} from '../dialogs/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router,
              private dialog: MatDialog) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(resolve => {
      // this.dialog.open(AlertComponent, {
      //   data: {'messageType': 7, 'messageText': ''}
      // });
      this.authService.checkToken()
        .subscribe(res => {
          if (res.success) {
            resolve(true);
            // this.dialog.closeAll();
          } else {
            this.authService.logout();
            //this.router.navigate(['/sso']).then();
            this.router.navigate([{ outlets: { popup: ['login'] } }]).then();
            resolve(false);
            // this.dialog.closeAll();
          }
          resolve(false);
            // this.dialog.closeAll();
        }, err => {
            this.authService.logout();
            //this.router.navigate(['/sso']).then();
            this.router.navigate([{ outlets: { popup: ['login'] } }]).then();
            resolve(false);
            // this.dialog.closeAll();
        }
        );
    });
  }
}
